import { useCallback, useContext, useEffect, useState } from "react";
import FlowBodyRender from "../Components/FlowBodyRender";
import ScheduleOrderSolution from "../Components/ScheduleOrderSolution";
import FileUploader from "../Steps/FileUploader";
import OrderReceipt from "../Steps/OrderReceipt";
import ProductListWithQty from "../Steps/ProductListWithQty";
import RefundMethod from "../Steps/RefundMethod";
import SearchProds from "../Steps/SearchProds";
import UserAcceptProduct from "../Steps/UserAcceptProduct";
import UserPaymentInfo from "../Steps/UserPaymentInfo";
import UserSolution from "../Steps/UserSolution";
import WizardLastStep from "../Steps/WizardLastStep";
import useWizardReset, { constants, shouldDoSolutionOrder } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";

const ConfusedOrder = ({ order }) => {

    const {
        getSolutions,
        resetWizardParams,
        handleHistorical,
        wizardIndex,
        steps,
        setSteps,
        setHistorical,
        solutions,
        flow,
        setFlow,
        resolveWizard
    } = useContext(WizardContext);

    const [clientFault, setClientFault] = useState({
        visible: false,
        message: ''
    });

    useEffect(() => {
        resetHistoricalWhenTypeChanged();
    }, [flow.conditions?.type]);

    useEffect(() => {
        handleGetNewSolutions();
    }, [flow]);

    const resetHistoricalWhenTypeChanged = () => {
        if (flow.conditions?.type !== constants.TYPE_CONFUSED) {
            setHistorical([]);
        }
    };

    const historicalFunction = useCallback((key, title = '', valueSelected) => {
        handleHistorical(key, title, valueSelected);

        handleResetWizardIfParamsChanged(valueSelected?.type ?? valueSelected, key, {
            [constants.PARAM_FAULT]: true,
            [constants.PARAM_TOTAL_ORDER_PAID]: true,
            [constants.PARAM_DIFFERENT_PRODUCTS]: true,
            [constants.PARAM_CONFUSED_PRODUCT_ACCEPTED]: true,
        });

    }, [wizardIndex, steps?.length, setFlow]);

    const isClientResponsible = () => {
        if (flow?.conditions?.fault === constants.CLIENT_FAULT) {
            setClientFault({
                visible: true,
                message: '⛔️ El cliente no tiene razón, no se puede continuar con el flujo'
            });
            resetWizardParams();
            return true;
        }
        return false;
    };

    const handleResetWizardIfParamsChanged = useWizardReset(flow, setClientFault, resetWizardParams);

    const handleGetNewSolutions = useCallback(() => {
        if (isClientResponsible()) return;

        const { conditions } = flow || {};
        if (conditions) {
            const {
                [constants.PARAM_FAULT]: fault,
                [constants.PARAM_TOTAL_ORDER_PAID]: total_order_payed,
                [constants.PARAM_DIFFERENT_PRODUCTS]: different_products,
                [constants.PARAM_CONFUSED_PRODUCT_ACCEPTED]: confused_product_accepted
            } = conditions;

            if (
                fault !== undefined &&
                total_order_payed !== undefined &&
                different_products?.length > 0 &&
                flow[constants.PARAM_PRODUCTS]?.length > 0 &&
                confused_product_accepted !== undefined
            ) {

                const solParams = {
                    fault,
                    total_order_payed,
                    different_products,
                    confused_product_accepted
                };
                getSolutions(order.compra_id, 'confused', solParams);
            }
        }
    }, [flow, getSolutions]);

    useEffect(() => {
        handleFlowStepsConfig();
    }, [flow, solutions, historicalFunction, order]);

    const handleFlowStepsConfig = () => {
        const baseSteps = [
            <OrderReceipt
                order={order}
                handleHistorical={historicalFunction} />,
            <FileUploader
                handleHistorical={historicalFunction} />,
            <OrderReceipt
                order={order}
                showUserProof={true}
                handleHistorical={historicalFunction} />,
            <UserPaymentInfo
                handleHistorical={historicalFunction} />,
            <ProductListWithQty
                handleHistorical={historicalFunction}
                products={order.allProductByPrv} />,
            <SearchProds
                order={order}
                handleHistorical={historicalFunction} />,
            <UserAcceptProduct
                handleHistorical={historicalFunction}
                param={constants.PARAM_CONFUSED_PRODUCT_ACCEPTED} />,
            <UserSolution
                handleHistorical={historicalFunction} />,
        ];

        const currentSolution = flow.conditions?.solution;
        const getSolutionObj = solutions.find(item => item.type === currentSolution);

        let finalStep;
        if (currentSolution) {
            if (shouldDoSolutionOrder(getSolutionObj)) {
                finalStep = (
                    <ScheduleOrderSolution
                        handleHistorical={historicalFunction}
                        order={order} />
                );
            } else {
                finalStep = (
                    <RefundMethod
                        handleHistorical={historicalFunction} />
                );
            }
        }

        setSteps([...baseSteps, finalStep, <WizardLastStep order={order} resolveWizard={resolveWizard} />]);
    };

    return <FlowBodyRender
        clientFault={clientFault}
        setClientFault={setClientFault} />
};

export default ConfusedOrder