// Routes
const ROUTES = {
  core_v2: {
    get_wizard_config: 'wizard/available-solutions/order/{id}',
    resolve_wizard: 'wizard/resolve',
    summary: 'wizard/summary/{id}',
  }
};

export default ROUTES;
