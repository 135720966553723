import { Col, Row, Select } from "antd";

const BooleanSelector = ({ title, placeholder = 'Seleccione una opción', onChange, value, icon = null, disabled = false }) => {
    return <Row style={{ marginTop: 10 }} >
        <Col span={24}>
            <Row className="d-flex align-items-center pb-2" style={{ gap: 5 }}>
                {icon}
                <strong style={{ fontSize: 14 }}>{title}</strong>
            </Row>
            <Select placeholder={placeholder} style={{ width: '100%' }} onChange={onChange} value={value} disabled={disabled}>
                <Select.Option key={1} value={true}>Si</Select.Option>
                <Select.Option key={2} value={false}>No</Select.Option>
            </Select>
        </Col>
    </Row>
};

export default BooleanSelector;