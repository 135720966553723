import { useContext } from "react";
import { constants } from "../Utils";
import BooleanSelector from "./BooleanSelector";
import { WizardContext } from "../Context/WizardProvider";

const UserAcceptsOption = ({ handleHistorical, param, title, icon }) => {

    const {
        setFlow
    } = useContext(WizardContext);

    const handleSelection = (value) => {
        setFlow(flow => ({ ...flow, conditions: { ...flow.conditions, [param]: value } }));
        handleHistorical(constants.PARAM_USER_ACCEPTS_REPROGRAMMING, title, value ? 'Sí' : 'No');
    };

    return <BooleanSelector onChange={handleSelection} title={title} icon={icon} />
};

export default UserAcceptsOption;