import axios from 'axios';

const TOKEN_NAME = 'mp_token_web';

axios.interceptors.request.use(function (config) {
    config.metadata = { startTime: new Date() };
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    response.config.metadata.endTime = new Date();
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
    return response;
}, function (error) {
    console.error("API Error:", error.response?.data || error.message);
    return Promise.reject(error);
});

export default class AuthService {

    constructor(domain) {
        this.domain = domain || process.env.REACT_APP_OLIVER_API_CORE;
    }


    async getPublic(url, params = false) {
        try {
            const response = await axios.get(`${this.domain}${url}`, {
                params,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(`Get error ${url}:`, error);
            throw error.response?.data?.message ?? error;
        }
    }

    async get(url, params = false, tempToken = null) {
        try {
            const response = await axios.get(`${this.domain}${url}`, {
                params,
                headers: {
                    'Authorization': `Bearer ${tempToken ?? this.getToken()}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(`Get error ${url}:`, error);
            throw error.response?.data?.message ?? error;
        }
    }

    async post(url, data, tempToken = null) {
        try {

            const response = await axios.post(`${this.domain}${url}`, data, {
                headers: {
                    'Authorization': `Bearer ${tempToken ?? this.getToken()}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error(`Post error ${url}:`, error);
            console.error('error.response:', error.response);
        }
    }

    async put(url, data) {
        try {
            const response = await axios.put(`${this.domain}${url}`, data, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error(`PUT error ${url}:`, error);
            throw error.response?.data?.message ?? error;
        }
    }

    getToken() {
        return localStorage.getItem(TOKEN_NAME);
    }

    logout() {
        localStorage.removeItem(TOKEN_NAME);
    }

    async isLogged() {
        return !!this.getToken();
    }
}
