// ORDER STATES
export const ORDER_STATES = {
    CREATED: 1,
    ASSIGNED: 2,
    IN_TRANSIT: 3,
    COMPLETED: 4,
    CANCELED: 5,
    TO_BE_RETURNED: 6,
    RETURNED: 7,
}

// ROUTE STATES
export const ROUTE_STATES = {
    CREATED: 1,
    READY_TO_START: 2,
    IN_PROGRESS: 3,
    COMPLETED: 4,
    CANCELED: 5,
}

// ROUTE POINTS STATES
export const ROUTE_POINT_STATES = {
    CREATED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    CANCELED: 4,
}

// ROUTE POINTS TYPES
export const ROUTE_POINT_TYPES = {
    PICKUP: 1,
    DELIVERY: 2,
}