import React, { useEffect, useState } from "react";
import { Card, Steps, Row, Col } from "antd";
import Mispichoshelper from "../../../utils/Mispichoshelper";
import { ORDER_STATES } from "../../../componentsTMS/Utils/Constants";
import Loading from "../../../components/componentsUI/Loading";

const { Step } = Steps;

const TmsOrders = ({ compra }) => {

    const [loading, setLoading] = useState(true);

    const [order, setOrder] = useState(null);


    const [steps, setSteps] = useState([]);

    useEffect(() => {

        getOrder();

    }, []);

    const getOrder = () => {

        setLoading(true);

        let params = {
            com_id: compra.compra_id
        }

        console.log(params);

        Mispichoshelper.externalLogistic.getOrder(params).then((response) => {
            if (response.payload) {
                setOrder(response.payload.order);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const Stepper = () => {

        console.log(ORDER_STATES);

        let states_keys = Object.keys(ORDER_STATES);
        let states_values = Object.values(ORDER_STATES);

        let current_step = 1;
        let steps = [
            {
                title: 'Creada',
                description: 'La orden ha sido creada'
            },
            {
                title: 'Asignada',
                description: 'La orden ha sido asignada a un transportista'
            },
            {
                title: 'En Tránsito',
                description: 'La orden se encuentra en tránsito'
            },
            {
                title: 'Completada',
                description: 'La orden ha sido completada'
            },
        ]

        // Normal Flow

        if ([ORDER_STATES.CREATED, ORDER_STATES.ASSIGNED, ORDER_STATES.IN_TRANSIT, ORDER_STATES.COMPLETED].includes(order.state_id)) {

            if (order.state_id === ORDER_STATES.CREATED) current_step = 0;
            if (order.state_id === ORDER_STATES.ASSIGNED) current_step = 1;
            if (order.state_id === ORDER_STATES.IN_TRANSIT) current_step = 2;
            if (order.state_id === ORDER_STATES.COMPLETED) current_step = 3;
        }

        // Cancelled Flow

        if ([ORDER_STATES.CANCELED].includes(order.state_id)) {

            // Eliminar ultimo step
            steps.pop();

            // Agregar step de cancelacion
            steps.push({
                title: 'Cancelada',
                description: 'La orden ha sido cancelada'
            });

            if (order.state_id === ORDER_STATES.COMPLETED) current_step = 3;
        }

        // 

        if ([ORDER_STATES.TO_BE_RETURNED, ORDER_STATES.RETURNED].includes(order.state_id)) {

            // Eliminar ultimo step
            steps.pop();

            // Agregar step de devolucion

            steps.push({
                title: 'Pediente de Devolución',
                description: 'La orden se encuentra pendiente de devolución'
            });

            steps.push({
                title: 'Devuelta',
                description: 'La orden ha sido devuelta'
            });

            if (order.state_id === ORDER_STATES.TO_BE_RETURNED) current_step = 3;
            if (order.state_id === ORDER_STATES.RETURNED) current_step = 4;
        }

        return (
            <Steps current={current_step} style={{ padding: 20 }}>
                {steps.map((step, index) => {
                    return (
                        <Step title={step.title} description={'2025-02-28 12:00'} />
                    )
                })}
            </Steps>
        )

    }

    if (loading) {
        return (
            <Card>
                <Loading />
            </Card>
        )
    }

    return (
        <Card>
            <Row style={{ marginBottom: 20 }}>
                <p>Orden: #{order.id}</p>
                <p>Ruta: {order.route && order.route.id}</p>
                <p>Driver: {order.route && order.route.driver && order.route.driver.name}</p>
            </Row>

            <Row>
                {Stepper()}
            </Row>
        </Card>
    )


}

export default TmsOrders;