import { Col, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FaCamera, FaReceipt, FaRegWindowClose } from "react-icons/fa";
import InnerImageZoom from 'react-inner-image-zoom';
import Mispichoshelper from "../../utils/Mispichoshelper";
import WizardLoading from "../Components/WizardLoading";
import UserBlame from "./UserBlame";
import BooleanSelector from "../Components/BooleanSelector";
import { constants } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";

const OrderReceipt = ({ order, showUserProof = false, handleHistorical }) => {

    const {
        flow,
        setFlow,
        images,
    } = useContext(WizardContext);

    const [digitals, setDigitals] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDigitals();
    }, []);

    const getDigitals = () => {
        setLoading(true);
        Mispichoshelper.getOrderDeliveriedDigitals({ order_id: order?.compra_id }).then(response => {
            const digitals = response?.payload && response.payload.length > 0 ? response.payload : [];
            setDigitals(digitals);
        }).catch(error => {
            alert('Error al obtener las fotos');
        })
            .finally(() => {
                setLoading(false)
            });
    };

    const handleSelection = (value) => {
        setFlow(flow => ({ ...flow, conditions: { ...flow.conditions, [constants.PARAM_RECEIPT]: value } }));
        handleHistorical(constants.PARAM_RECEIPT, '¿El comprobante es correcto?', value ? 'Sí' : 'No');
    };

    return <Row style={{ marginTop: 5, padding: 10 }} >
        {loading ? <WizardLoading />
            : <>
                <Row gutter={[10, 10]}>
                    <Col span={showUserProof ? 12 : 24}>
                        <FaCamera color="grey" size={15} /><span style={{ marginLeft: 5, fontSize: 12, color: 'grey' }}>Comprobante de orden</span>
                        {digitals?.length ? digitals.map(digital =>
                            <Row key={digital.id} style={{ marginTop: 10 }}>
                                <Col>
                                    <InnerImageZoom width={'200'} src={digital.url_digital} />
                                </Col>
                            </Row>
                        )
                            : <Row>
                                <Col className='d-flex justify-content-start'>
                                    <span style={{ fontStyle: 'italic', color: 'darkgrey' }}>No existen comprobantes subidos por el petshop</span>
                                </Col>
                            </Row>}
                        <hr></hr>
                    </Col>
                    {showUserProof ?
                        <Col span={12}>
                            <FaCamera color="grey" size={15} /><span style={{ marginLeft: 5, fontSize: 12, color: 'grey' }}>Fotos enviadas por el cliente</span>
                            {
                                images.length ? images.map(image =>
                                    <Row key={image.id} style={{ marginTop: 10 }}>
                                        <Col className='d-flex justify-content-start'>
                                            <InnerImageZoom width={'200'} src={image.dataURL} />
                                        </Col>
                                    </Row>
                                ) : <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <FaRegWindowClose size={20} style={{ margin: 10 }} />
                                    </Col>
                                </Row>
                            }
                        </Col> :
                        <BooleanSelector title='¿El comprobante es correcto?'
                            placeholder='Seleccione una opción'
                            onChange={handleSelection}
                            icon={<FaReceipt color="deepskyblue" />} />
                    }
                    {showUserProof && <UserBlame
                        flow={flow}
                        setFlow={setFlow}
                        handleHistorical={handleHistorical}
                    />}
                </Row>
            </>
        }
    </Row>
};

export default OrderReceipt;