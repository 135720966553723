import { useCallback, useContext, useEffect, useState } from "react";
import FlowBodyRender from "../Components/FlowBodyRender";
import ScheduleOrderSolution from "../Components/ScheduleOrderSolution";
import { WizardContext } from "../Context/WizardProvider";
import FileUploader from "../Steps/FileUploader";
import OrderReceipt from "../Steps/OrderReceipt";
import ProductListWithQty from "../Steps/ProductListWithQty";
import RefundMethod from "../Steps/RefundMethod";
import UserAcceptProduct from "../Steps/UserAcceptProduct";
import UserPaymentInfo from "../Steps/UserPaymentInfo";
import UserSolution from "../Steps/UserSolution";
import WizardLastStep from "../Steps/WizardLastStep";
import useWizardReset, { constants, shouldDoSolutionOrder } from "../Utils";

const DamagedOrExpiredProductOrder = ({ order }) => {

    const {
        flow,
        setFlow,
        handleHistorical,
        historical,
        setHistorical,
        wizardIndex,
        setWizardIndex,
        steps,
        setSteps,
        solutions,
        resetWizardParams,
        getSolutions,
        images,
        setImages,
        resolveWizard
    } = useContext(WizardContext);

    const [clientFault, setClientFault] = useState({
        visible: false,
        message: ''
    });

    useEffect(() => {
        resetHistoricalWhenTypeChanged();
    }, [flow.conditions?.type]);

    useEffect(() => {
        handleGetNewSolutions();
    }, [flow?.conditions]);

    const historicalFunction = useCallback((key, title = '', valueSelected) => {
        handleHistorical(key, title, valueSelected);

        handleResetWizardIfParamsChanged(valueSelected?.type ?? valueSelected, key, {
            fault: true,
            total_order_payed: true,
            broked_product_accepted: true
        });
    }, [wizardIndex, steps?.length, setFlow]);

    useEffect(() => {
        handleFlowStepsConfig();
    }, [flow, solutions, historicalFunction, order]);

    const resetHistoricalWhenTypeChanged = () => {
        if (flow.conditions?.type !== constants.TYPE_BROKED) {
            setHistorical([]);
        }
    };

    const isClientResponsible = () => {
        if (flow?.conditions?.fault === constants.CLIENT_FAULT) {
            setClientFault({
                visible: true,
                message: 'El cliente no tiene razón, no se puede continuar con el flujo'
            });
            resetWizardParams();
        }
    };

    const handleResetWizardIfParamsChanged = useWizardReset(flow, setClientFault, resetWizardParams);

    const handleGetNewSolutions = useCallback(() => {
        isClientResponsible();

        const { conditions } = flow || {};
        if (conditions) {
            const {
                [constants.PARAM_FAULT]: fault,
                [constants.PARAM_TOTAL_ORDER_PAID]: total_order_payed,
                [constants.PARAM_BROKED_PRODUCT_ACCEPTED]: broked_product_accepted
            } = conditions;

            if (
                fault !== undefined &&
                total_order_payed !== undefined &&
                flow[constants.PARAM_PRODUCTS]?.length > 0 &&
                broked_product_accepted !== undefined
            ) {
                const solParams = {
                    fault,
                    total_order_payed,
                    broked_product_accepted
                };

                getSolutions(order.compra_id, 'broken', solParams);
            }
        }
    }, [flow, getSolutions]);

    const handleFlowStepsConfig = () => {
        const baseSteps = [
            <OrderReceipt
                flow={flow}
                setFlow={setFlow}
                order={order}
                images={images}
                handleHistorical={historicalFunction}
            />,
            <FileUploader
                setFlow={setFlow}
                setImages={setImages}
                images={images}
                handleHistorical={historicalFunction}
            />,
            <OrderReceipt
                flow={flow}
                setFlow={setFlow}
                order={order}
                images={images}
                showUserProof={true}
                handleHistorical={historicalFunction}
            />,
            <UserPaymentInfo
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
            />,
            <ProductListWithQty
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
                products={order.allProductByPrv}
            />,
            <UserAcceptProduct
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
                param={constants.PARAM_BROKED_PRODUCT_ACCEPTED}
            />,
            <UserSolution
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
                solutions={solutions}
            />,
        ];

        const currentSolution = flow.conditions?.solution;
        const getSolutionObj = solutions.find(item => item.type === currentSolution);

        let finalStep;
        if (currentSolution) {
            if (shouldDoSolutionOrder(getSolutionObj)) {
                finalStep = (
                    <ScheduleOrderSolution
                        flow={flow}
                        setFlow={setFlow}
                        handleHistorical={historicalFunction}
                        order={order}
                    />
                );
            } else {
                finalStep = (
                    <RefundMethod
                        flow={flow}
                        setFlow={setFlow}
                        handleHistorical={historicalFunction}
                    />
                );
            }
        }

        setSteps([...baseSteps, finalStep, <WizardLastStep order={order} resolveWizard={resolveWizard} />]);
    };

    return <FlowBodyRender
        steps={steps}
        wizardIndex={wizardIndex}
        setWizardIndex={setWizardIndex}
        historical={historical}
        clientFault={clientFault}
        setClientFault={setClientFault} />
};

export default DamagedOrExpiredProductOrder;