import { useCallback, useContext, useEffect, useState } from "react";
import { FaBoxOpen, FaClock, FaEdit } from "react-icons/fa";
import EditProducts from "../../componentsCustomerService/Order/Actions/EditProducts";
import Refund from "../../componentsCustomerService/Order/Actions/Refund";
import OrderSplit from "../../componentsCustomerService/Order/Actions/Split";
import FlowBodyRender from "../Components/FlowBodyRender";
import UserAcceptsOption from "../Components/Reprogramming";
import UserSolution from "../Steps/UserSolution";
import { constants } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";
import ProductListWithQty from "../Steps/ProductListWithQty";

const UnresolvedByStock = ({ order, functions, showModals }) => {

    const {
        flow,
        setFlow,
        solutions,
        getSolutions,
        handleHistorical,
        setHistorical,
        wizardIndex,
        steps,
        setSteps,
        setWizardIndex
    } = useContext(WizardContext);

    const [clientFault, setClientFault] = useState({
        visible: false,
        message: ''
    });

    useEffect(() => {
        resetHistoricalWhenTypeChanged();
    }, [flow.conditions?.type]);

    useEffect(() => {
        handleGetNewSolutions();
    }, [flow]);

    const resetHistoricalWhenTypeChanged = () => {
        if (flow.conditions?.type !== constants.TYPE_CONFUSED) {
            setHistorical([]);
        }
    };

    const historicalFunction = useCallback((key, title = '', valueSelected) => {
        handleHistorical(key, title, valueSelected);
    }, [wizardIndex, steps?.length, setFlow]);

    const handleGetNewSolutions = useCallback(() => {

        const { conditions } = flow || {};
        if (conditions) {
            const {
                [constants.PARAM_USER_ACCEPTED_CHANGE]: user_accepted_change,
                [constants.PARAM_USER_ACCEPTED_PRODUCTS]: user_accepted_products,
                [constants.PARAM_USER_ACCEPTS_REPROGRAMMING]: user_accepts_reprogramming,
            } = conditions;

            if (
                (user_accepted_change === false && user_accepted_products !== undefined) ||
                (user_accepted_change === true && user_accepts_reprogramming !== undefined)
            ) {

                const solParams = {
                    user_accepted_change,
                    user_accepted_products,
                    user_accepts_reprogramming
                };

                getSolutions(order.compra_id, 'stock', solParams);
            }
        }
    }, [flow, getSolutions]);

    useEffect(() => {
        handleFlowStepsConfig();
    }, [flow, solutions, historicalFunction, order]);

    const handleCloseLegacyModal = (modal) => {

        setFlow(flow => ({
            ...flow,
            conditions: {
                ...flow.conditions,
                solution: null
            }
        }));
        setWizardIndex(wizardIndex - 1);
        functions.toggleModal(modal, false);
    }

    const handleSolutionComponentRendering = (solution) => {
        console.log('solution', solution);
        switch (solution) {
            case constants.EDIT_ORDER_SOLUTION: {
                return <EditProducts
                    editaCantidadProducto={functions.editaCantidadProducto}
                    editarCompra={true}
                    openedFromWizard={true}
                    refrescarCompra={functions.refrescarCompra}
                    toggleModal={functions.toggleModal}
                    hideFromWizard={() => handleCloseLegacyModal(constants.EDIT_ORDER_LEGACY_MODAL_KEY)}
                    compra={order} />;
            }

            case constants.SPLIT_ORDER_SOLUTION: {
                return <OrderSplit
                    compra={order}
                    showSplitCompra={() => showModals.splitCompra(true)}
                    refrescarCompra={functions.refrescarCompra}
                    hideSplitCompra={functions.toggleModal}
                    hideFromWizard={() => handleCloseLegacyModal(constants.SPLIT_LEGACY_MODAL_KEY)} />;
            }

            case constants.CANCEL_ORDER_SOLUTION: {
                return <Refund
                    compra={order}
                    refrescarCompra={functions.refrescarCompra}
                    hideReembolso={functions.toggleModal}
                    hideFromWizard={() => handleCloseLegacyModal(constants.REFUND_LEGACY_MODAL_KEY)}
                    showReembolso={true}
                    forceMotive={constants.BLAME_MIS_PICHOS}
                />
            }

            default:
                break;
        }
    };

    const handleFlowStepsConfig = () => {
        const userAcceptsOrderChanges = flow.conditions?.user_accepted_change;
        const beforeDeliveryTime = flow.conditions?.before_delivery_time;
        const solutionComponent = solutions.find(sol => sol.type === flow.conditions?.solution)?.component;

        const baseSteps = [
            <UserAcceptsOption
                handleHistorical={historicalFunction}
                param={constants.PARAM_USER_ACCEPTED_CHANGE}
                title={'¿El cliente acepta modificar la orden (diferente cantidad / producto similar)?'}
                icon={<FaEdit size={15} color="deepskyblue" />}
            />,
        ];

        const acceptedFlow = [
            <UserAcceptsOption
                handleHistorical={historicalFunction}
                param={constants.PARAM_USER_ACCEPTS_REPROGRAMMING}
                title={'¿El cliente acepta reprogramar?'}
                icon={<FaClock size={15} color="deepskyblue" />}
            />,

            <UserSolution
                handleHistorical={historicalFunction}
            />,
            handleSolutionComponentRendering(solutionComponent)
        ];

        if (!userAcceptsOrderChanges) {
            baseSteps.push(
                <UserAcceptsOption
                    handleHistorical={historicalFunction}
                    param={constants.PARAM_USER_ACCEPTED_PRODUCTS}
                    title={'¿El cliente desea mantener el restante de productos?'}
                    icon={<FaBoxOpen size={15} color="deepskyblue" />}
                />,
                <UserSolution
                    handleHistorical={historicalFunction}
                />,
                handleSolutionComponentRendering(solutionComponent)
            );
        }
        else {
            baseSteps.push(...acceptedFlow);
        }

        if (beforeDeliveryTime) {
            baseSteps.push(
                <UserSolution
                    handleHistorical={historicalFunction}
                />,
                handleSolutionComponentRendering(solutionComponent)
            );
        }

        setSteps(baseSteps);
    };

    return <FlowBodyRender
        clientFault={clientFault}
        setClientFault={setClientFault} />
};

export default UnresolvedByStock;