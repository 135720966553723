import { useCallback, useContext, useEffect, useState } from "react";
import ChangeDeliveryTime from "../../componentsCustomerService/Order/Actions/ChangeDeliveryTime";
import Refund from "../../componentsCustomerService/Order/Actions/Refund";
import FlowBodyRender from "../Components/FlowBodyRender";
import UserAcceptsOption from "../Components/Reprogramming";
import UserSolution from "../Steps/UserSolution";
import { constants } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";
import { Button } from "antd";
import ToContactCS from "../../componentsCustomerService/Order/Actions/ToContactCS";

const UnresolvedByLogistic = ({ order, functions, showModals }) => {

    const {
        flow,
        setFlow,
        resetWizardParams,
        solutions,
        setOption,
        getSolutions,
        setWizardIndex,
        handleHistorical,
        setHistorical,
        wizardIndex,
        steps,
        setSteps
    } = useContext(WizardContext);

    const [clientFault, setClientFault] = useState({
        visible: false,
        message: ''
    });

    useEffect(() => {
        resetHistoricalWhenTypeChanged();
    }, [flow.conditions?.type]);

    useEffect(() => {
        handleGetNewSolutions();
    }, [flow]);

    const resetHistoricalWhenTypeChanged = () => {
        if (flow.conditions?.type !== constants.TYPE_CONFUSED) {
            setHistorical([]);
        }
    };

    const historicalFunction = useCallback((key, title = '', valueSelected) => {
        handleHistorical(key, title, valueSelected);
    }, [wizardIndex, steps?.length, setFlow]);

    const handleGetNewSolutions = useCallback(() => {
        const { conditions } = flow || {};
        if (conditions) {
            const {
                [constants.PARAM_USER_ACCEPTS_REPROGRAMMING]: user_accepts_reprogramming,
            } = conditions;

            if (
                user_accepts_reprogramming !== undefined
            ) {

                const solParams = {
                    user_accepts_reprogramming,
                };

                getSolutions(order.compra_id, 'logistic', solParams);
            }
        }
    }, [flow, getSolutions]);

    const handleCloseLegacyModal = (modal) => {

        setFlow(flow => ({
            ...flow,
            conditions: {
                ...flow.conditions,
                solution: null
            }
        }));
        setWizardIndex(wizardIndex - 1);
        functions.toggleModal(modal, false);
    }

    const handleSolutionComponentRendering = (solution) => {

        switch (solution) {
            case constants.USER_ACCEPTS_REPROGRAMMING_SOLUTION: {
                return <ChangeDeliveryTime
                    refrescarCompra={functions.refrescarCompra}
                    showReprogramar={() => functions.toggleModal('reprogramarCompra', true)}
                    forceMotive={constants.BLAME_MP_DELIVERY_MOTIVE}
                    hideFromWizard={() => handleCloseLegacyModal(constants.REPROGRAMMING_ORDER_LEGACY_MODAL_KEY)}
                    hideReprogramar={() => window.location.reload()}
                    compra={order} />
            }


            case constants.CANCEL_ORDER_SOLUTION: {
                return <Refund
                    compra={order}
                    refrescarCompra={functions.refrescarCompra}
                    hideReembolso={() => window.location.reload()}
                    hideFromWizard={() => handleCloseLegacyModal(constants.REFUND_LEGACY_MODAL_KEY)}
                    showReembolso={true}
                    forceMotive={constants.BLAME_MIS_PICHOS}
                />
            }

            case constants.USER_WANTS_TO_BE_CONTACTED_SOLUTION: {
                return <ToContactCS
                    compra={order}
                    showModals={{ showToContactByCs: true }}
                    refrescarCompra={functions.refrescarCompra}
                    hideFromWizard={() => handleCloseLegacyModal(constants.CS_CONTACT_LEGACY_MODAL_KEY)}
                    close={functions.toggleModal} />
            }

            default:
                break;
        }
    };

    useEffect(() => {
        handleFlowStepsConfig();
    }, [flow, solutions, historicalFunction, order]);

    const handleFlowStepsConfig = () => {
        const componentToRender = solutions.find(solution => solution.type === flow.conditions?.solution)

        const baseSteps = [
            <UserAcceptsOption
                handleHistorical={historicalFunction}
                param={constants.PARAM_USER_ACCEPTS_REPROGRAMMING}
                title={'¿El cliente acepta reprogramar?'}
            />,
            <UserSolution
                handleHistorical={historicalFunction}
            />
        ];

        if (componentToRender && componentToRender.component) {
            baseSteps.push(
                handleSolutionComponentRendering(componentToRender.component)
            );
        }

        setSteps(baseSteps);
    };

    const handleChangeUnresolvedFlow = () => {
        resetWizardParams();
        setOption('stock')
    };

    return <>
        <p className="p-2 mt-2 mb-3 text-center" style={{ backgroundColor: 'rgb(161, 161, 161)', color: 'white', borderRadius: 15 }}>Esta opción debe ser elegida únicamente si todos los productos de la orden no tienen problemas de stock. Caso contrario deberá seleccionar el flujo de
            <Button
                onClick={handleChangeUnresolvedFlow}
                style={{ border: 'none', backgroundColor: 'transparent', padding: 0, color: 'blue', fontWeight: 'bold', marginLeft: 5 }}>
                problemas de stock
            </Button>
            .</p>

        <FlowBodyRender
            clientFault={clientFault}
            setClientFault={setClientFault} />
    </>
};

export default UnresolvedByLogistic;