import { Checkbox, Col, Radio, Row, Select, Tabs, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { FaDotCircle, FaPaw, FaTruck } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import Mispichoshelper from "../../utils/Mispichoshelper";
import { constants } from "../Utils";
import WizardLoading from "./WizardLoading";
import { WizardContext } from "../Context/WizardProvider";

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const ScheduleOrderSolution = ({ order, showMotives = false, forceMotive = null, handleHistorical }) => {

    const {
        flow,
        setFlow
    } = useContext(WizardContext);

    const [times, setTimes] = useState(null);
    const [loadingTimes, setLoadingTimes] = useState(true);
    const [allAvailablePetshops, setAllAvailablePetshops] = useState(false);
    const [noFilters, setNoFilters] = useState(false);
    const [logistic_type, setTypeLogistic] = useState(null);
    const [petshops, setPetshops] = useState([]);
    const [timID, setTimID] = useState(null);
    const [date, setDate] = useState(null);
    const [loadingPets, setLoadingPets] = useState(false);
    const [petSelected, setPetSelected] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [motives, setMotives] = useState([]);
    const [motiveSelected, setMotiveSelected] = useState(forceMotive);

    const [logisticAvailable, setLogisticAvaiable] = useState(false);
    const [petListAvailable, setPetListAvailable] = useState(false);

    useEffect(() => {
        setLogisticAvaiable(timID && date);
    }, [timID, date]);

    useEffect(() => {
        setPetListAvailable((logisticAvailable && logistic_type) ? true : false)
    }, [logisticAvailable, logistic_type]);

    useEffect(() => {
        getReturnOrderSchedule();
        showMotives && getReprogrammingMotives();
    }, [allAvailablePetshops, noFilters]);

    useEffect(() => {
        if (petSelected) {
            setFlow(flow => ({ ...flow, conditions: { ...flow?.conditions, puv_id: petSelected } }));
        }
    }, [petSelected]);

    useEffect(() => {
        if (timID && date && logistic_type) {
            getPetshopListForReprogramming();
            if (petSelected)
                handleHistorical(constants.PARAM_SOLUTION_ORDER, 'Orden de solución: ', 'Reprogramar');
        }
    }, [timID, date, logistic_type, petSelected]);

    const getReturnOrderSchedule = () => {
        setLoadingTimes(true);

        setPetshops([])

        const params = {
            solo_proveedor_compra: allAvailablePetshops,
            sin_filtros: noFilters ? 1 : 0,
            compra_id: order?.compra_id,
            logistic_type: logistic_type
        };

        Mispichoshelper.getHorariosDeReprogramacion(params)
            .then((response) => {
                setTimes(response?.payload)
                setLoadingTimes(false);
            });
    };

    const getReprogrammingMotives = () => {
        Mispichoshelper.getReproggramingReasons().then(response => {
            const filteredMotives = response.payload.filter(item => item.nombre !== 'Responsabilidad cliente')
            setMotives(filteredMotives)
        })
            .catch(() => alert('Error al obtener los motivos de reprogramación'))
    };

    const getPetshopListForReprogramming = () => {
        setLoadingPets(true)

        Mispichoshelper.getPetshopsForReproggraming({
            tim_id: timID,
            date: date,
            compra_id: order.compra_id,
            logistic_type: logistic_type
        }).then(response => {
            setPetshops(response.payload)
        }).catch(error => {
            alert('Error al obtener los petshops disponibles')
        }).finally(() => {
            setLoadingPets(false);
        });
    };

    const handleDateSelected = (evt) => {
        setSelectedValue(evt.target.value);
        const date = evt.target.value.split(',')[1];
        const tim_id = evt.target.value.split(',')[0];
        setFlow(flow => ({ ...flow, conditions: { ...flow?.conditions, tim_id: parseInt(tim_id), date: date } }));
        setTimID(tim_id);
        setDate(date);
    };

    const handleOrderTitle = () => {
        if (flow.conditions?.solution === 'partial_refund_with_card' || flow.conditions?.solution === 'partial_refund_with_credit') return 'de retiro';
        if (flow.conditions?.solution === 'complete_order' && flow.conditions?.confused_product_accepted === false) return 'de retiro y solución';

    };

    return <Row style={{ marginTop: 10, padding: 10 }}>
        <div className="d-flex align-items-center" style={{ gap: 5 }}>
            <FaDotCircle color={"deepskyblue"} />
            <strong style={{ fontSize: 14 }}>Gestionar orden {handleOrderTitle()}</strong>
        </div>
        {showMotives && <Col span={24} style={{ marginTop: 10, zIndex: 999 }}>
            <strong> Motivos de reprogramación</strong>
            <Select className="my-1" style={{ width: "100%" }}
                onChange={(value) => setMotiveSelected(value)}
                value={motiveSelected}
                placeholder="Selecciona un motivo"
                showSearch
                filterOption={(input, option) =>
                    option.props && option.props.children && option.props.children[0] && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {motives.map((motive, index) => {
                    return <Option key={index} value={motive.id}>{motive.nombre}</Option>
                })}
            </Select>
        </Col>}
        <Col span={24} style={{ marginTop: 10 }}>
            <strong style={{ marginRight: 5 }}> Filtros</strong>
            <Tooltip title="Capacidad: No es capacidad del Petshop, sino la capacidad de compras por día que indica en la tabla configuración.                  
            Inicio de franja: no descarta los bloques horarios que ya empezaron" >
                <FcInfo size={18} />
            </Tooltip>
            <Row style={{ width: '100%', marginTop: 10 }}>
                <Checkbox checked={allAvailablePetshops} onChange={evt => setAllAvailablePetshops(evt.target.checked)}>Todos los petshops disponibles</Checkbox>
                <Checkbox checked={noFilters} onChange={evt => setNoFilters(evt.target.checked)}>Sin filtro ( de capacidad e inicio de franja )</Checkbox>
            </Row>

            {loadingTimes ? <WizardLoading />
                : <Row style={{ width: '100%', marginTop: 10 }}>
                    <strong>Configure la orden</strong>
                    {times?.length > 0 ?
                        <Tabs defaultActiveKey="1" style={{ zIndex: 999 }}>
                            {times.map((cada_fecha, index) =>
                                <TabPane tab={cada_fecha.nombre.toUpperCase() + ' ' + cada_fecha.numero_dia} key={index + 1}>
                                    <Radio.Group onChange={handleDateSelected} value={selectedValue}>
                                        {cada_fecha.horarios.map((cada_horario, i) => (
                                            <Radio
                                                key={i}
                                                value={`${cada_horario.tim_id},${cada_fecha.fecha}`}
                                            >
                                                De {cada_horario.tim_inicio} {cada_horario.tim_fin} hs
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </TabPane>
                            )}
                        </Tabs>
                        :
                        <div ><br /> <label>Sin horarios de entrega</label> </div>
                    }
                </Row>}
        </Col>

        <Row>

            <Col span={12} style={{ marginTop: 10, padding: 3, opacity: !logisticAvailable ? .4 : 1 }}>
                <FaTruck size={15} /><strong> Logístsica</strong>
                <br />
                <Select style={{ width: "100%", marginTop: 10, zIndex: 999 }}
                    disabled={!logisticAvailable}
                    onChange={(value) => setTypeLogistic(value)}
                    value={logistic_type}
                    placeholder="Selecciona un tipo de logistica"
                    showSearch
                    filterOption={(input, option) =>
                        option.props && option.props.children && option.props.children[0] && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option key={constants.PETSHOP_LOGISTIC} value={constants.PETSHOP_LOGISTIC}>{constants.PETSHOP_LOGISTIC}</Option>
                    <Option key={constants.COLLECT_LOGISTIC} value={constants.COLLECT_LOGISTIC}>{constants.COLLECT_LOGISTIC}</Option>
                    <Option key={constants.ON_DEMAND_LOGISTIC} value={constants.ON_DEMAND_LOGISTIC}>{constants.ON_DEMAND_LOGISTIC}</Option>
                </Select>

            </Col>

            <Row>
                <Col span={12} style={{ marginTop: 10, padding: 3, opacity: !petListAvailable ? .4 : 1 }}>
                    <FaPaw size={15} /><strong> Petshops disponibles</strong>
                    <Select style={{ width: "100%", marginTop: 10 }}
                        onChange={(value) => setPetSelected(value)}
                        defaultValue={null}
                        loading={loadingPets}
                        disabled={!petListAvailable}
                        placeholder="Selecciona un petshop"
                        showSearch
                        filterOption={(input, option) =>
                            option.props && option.props.children && option.props.children[0] && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={petSelected}
                    >
                        {petshops.map((pet, ind) =>
                            <Option key={ind} value={pet.puv_pro_id} >
                                {pet.puv_nombre}
                                {pet.distancia ? (pet.distancia / 1000) + "km " : ""}
                                {pet.tiene_capacidad ? " [Capacidad: Si] " : " [Capacidad: No]"}
                            </Option>
                        )}

                    </Select>
                    <p style={{ color: 'grey', fontWeight: 'bold', fontSize: 12 }}>Actual: {order.compra_petshop}</p>
                </Col>
            </Row>
        </Row>
    </Row>
};

export default ScheduleOrderSolution;