import { Modal, Row } from "antd";
import Controls from "./Controls";
import Historical from "./Historical";
import { useContext } from "react";
import { WizardContext } from "../Context/WizardProvider";

const FlowBodyRender = ({ clientFault, setClientFault }) => {

    const {
        steps,
        wizardIndex,
        setWizardIndex,
        historical,
    } = useContext(WizardContext);

    return <>
        <Modal title="Atención" visible={clientFault.visible} footer={null} onCancel={() => setClientFault({
            visible: false,
            message: ''
        })}>
            <p>{clientFault.message}</p>
        </Modal>

        <Historical historical={historical} wizardIndex={wizardIndex} />
        <hr />
        {
            wizardIndex < steps.length - 1
                ? <span>Complete debajo para continuar con el flujo de reclamo</span>
                : <span>Fin del flujo de reclamo</span>

        }
        <Row style={{ width: '100%', border: '1px solid #ccc', borderRadius: 5, padding: 10, marginTop: 10 }}>
            {steps[wizardIndex]}
        </Row>

        <Controls
            wizardIndex={wizardIndex}
            setWizardIndex={setWizardIndex}
            steps={steps}
            historical={historical}
        />
    </>
};

export default FlowBodyRender;