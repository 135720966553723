import { Modal, Row, Select } from "antd";
import { useContext, useEffect } from "react";
import { FaThumbtack } from "react-icons/fa";
import WizardLoading from "./Components/WizardLoading";
import { WizardContext } from "./Context/WizardProvider";
import ConfusedOrder from "./Flows/ConfusedOrder";
import DamagedOrExpiredProductOrder from "./Flows/DamagedOrExpiredProductOrder";
import IncompleteOrder from "./Flows/IncompleteOrder";
import OrderNotDelivered from "./Flows/OrderNotDelivered";
import UnresolvedByLogistic from "./Flows/UnresolvedByLogistic";
import UnresolvedByStock from "./Flows/UnresolvedByStock";

const confirm = Modal.confirm;

const WizardModal = ({ order, showModals, functions }) => {

    const {
        setFlow,
        wizard,
        loading,
        option,
        resolvedWizard,
        solutionError,
        showConfirmationModal,
        setShowConfirmationModal,
        modalWizardResolved,
        getSolutions,
        handleMotiveSelection,
        resolveWizardConfirmation,
        handleWizardResetForMismatchSolutions,
        handleUserPaymentInfoRendering,
        resolveWizard
    } = useContext(WizardContext);

    useEffect(() => {
        getSolutions(order?.compra_id);
        handleUserPaymentInfoRendering(order);
    }, []);

    useEffect(() => {
        if (option) {
            setFlow({ conditions: { problem: option } });
            handleFlowRendering(option);
        }
    }, [option]);

    const handleCloseWizardAfterError = () => {
        handleWizardResetForMismatchSolutions();
        wizard.length === 0 && functions.toggleModal('showNewWizard', false);
    };

    const handleFlowRendering = () => {
        switch (option) {
            case 'incomplete':
                return <IncompleteOrder order={order} />;

            case 'confused':
                return <ConfusedOrder order={order} />;

            case 'broken':
                return <DamagedOrExpiredProductOrder order={order} />;

            case 'expired':
                return <DamagedOrExpiredProductOrder order={order} />;

            case 'not_delivered':
                return <OrderNotDelivered order={order} />;

            case 'logistic':
                return <UnresolvedByLogistic order={order} functions={functions} showModals={showModals} />;

            case 'stock':
                return <UnresolvedByStock order={order} functions={functions} showModals={showModals} />;

            default:
                return null;
        }
    };

    return <>

        <Modal title="Solicitud enviada ✅"
            cancelButtonProps={{ style: { display: 'none' } }}
            visible={modalWizardResolved}
            onOk={() => functions.toggleModal('showNewWizard', false)}
            okText="Salir">
            <Row className="p-3">
                <h4>🗒️ {resolvedWizard?.title} - <strong>#{order.compra_id}</strong></h4>
                <hr />
                <p className="pt-2" style={{ fontWeight: 'bold' }}>Resumen de la solicitud:</p>
                <span style={{ fontStyle: 'italic' }}>{resolvedWizard?.response?.join('. ')}</span>
                {resolvedWizard && <ul>

                    {resolvedWizard.reactions.length > 0 && resolvedWizard.reactions.map((reaction, index) => {
                        return <>
                            <hr />
                            <li key={index}><strong>{reaction.title}</strong> ➡ {reaction.solution.response.join('. ')}</li>
                        </>
                    })}
                </ul>}

            </Row>
        </Modal>

        <Modal title="⚠️ Atención"
            closable={false}
            cancelButtonProps={{ style: { display: 'none' } }}
            visible={solutionError.visible}
            onOk={() => handleCloseWizardAfterError()}
            okText="Volver">
            <p>⛔️ {solutionError.message}</p>
        </Modal>

        <Modal title="Confirmación de solicitud"
            visible={showConfirmationModal}
            onOk={resolveWizard}
            onCancel={() => setShowConfirmationModal(false)}
            style={{ padding: 10 }}>
            <p>¿Estás seguro de que deseas enviar la solicitud de reclamo?</p>
        </Modal>

        <Modal title="Solicitud de reclamo"
            visible={showModals.showNewWizard}
            onOk={resolveWizardConfirmation}
            width={option ? 800 : 500}
            onCancel={() => confirm({
                title: '¿Está seguro que desea salir del proceso de reclamos?',
                content: 'Se perderán todos los datos cargados hasta el momento.',
                cancelText: 'Volver',
                okText: 'Si',
                onOk: () => functions.toggleModal('showNewWizard', false)
            })}
            footer={null} >
            {
                loading ? <WizardLoading /> : <Row style={{ width: '100%' }}>
                    <span>Seleccione el motivo del reclamo</span>
                    <Row class="d-flex align-items-center w-100 justify-space-between">
                        <Select suffixIcon={<FaThumbtack />} style={{ width: '100%', fontWeight: 'bold' }} onSelect={(value) => handleMotiveSelection(value)} value={option} disabled={option ? true : false}>
                            {wizard.map((w, index) => (
                                <Select.Option key={index} value={w.type}>{w.title}</Select.Option>
                            ))}
                        </Select>
                    </Row>
                </Row>
            }
            {handleFlowRendering()}
        </Modal>
    </>
};

export default WizardModal;