import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

const Controls = ({ steps, historical, wizardIndex = 0, setWizardIndex = () => { } }) => {

    const [isNextStepAvailable, setIsNextStepAvailable] = useState(((wizardIndex < steps.length - 1) && historical[wizardIndex]?.value) ? true : false);

    // Validar si el siguiente paso está disponible, teniendo en cuenta si el paso actual fue completado
    useEffect(() => {
        setIsNextStepAvailable(((wizardIndex < steps.length - 1) && historical[wizardIndex]?.value) ? true : false);
    }, [wizardIndex, steps.length, historical]);

    const handleNextWizardItem = () => {
        if (wizardIndex < steps.length - 1) {
            setWizardIndex(wizardIndex + 1);
        }
    };

    const handlePrevWizardItem = () => {
        setWizardIndex(wizardIndex - 1);
    };

    return <Row style={{ width: '100%', marginTop: 10 }}>
        <Col span={24} style={{ justifyContent: 'center', display: 'flex' }}>
            <Button
                onClick={handlePrevWizardItem}
                disabled={wizardIndex <= 0}
                style={{ border: 'none' }}
            >
                <FaArrowAltCircleLeft
                    size={30}
                    color={wizardIndex <= 0 ? 'lightgrey' : "deepskyblue"}
                />
            </Button>

            <Button onClick={handleNextWizardItem} style={{ border: 'none', backgroundColor: 'transparent' }} disabled={!isNextStepAvailable}>
                <FaArrowAltCircleRight size={30} color={!isNextStepAvailable ? 'lightgrey' : "deepskyblue"} />
            </Button>
        </Col>
    </Row>
};

export default Controls;