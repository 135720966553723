import { FaCheck } from "react-icons/fa";
import BooleanSelector from "../Components/BooleanSelector";
import { WizardContext } from "../Context/WizardProvider";
import { useContext } from "react";

const UserAcceptProduct = ({ handleHistorical, param }) => {

    const {
        setFlow
    } = useContext(WizardContext);

    const handleSelection = (value) => {
        setFlow(flow => ({ ...flow, conditions: { ...flow.conditions, [param]: value } }));

        handleHistorical(param, '¿El cliente acepta quedarse con el producto?', value ? 'Sí' : 'No');
    };

    return <BooleanSelector onChange={handleSelection} title='¿El cliente acepta quedarse con el producto?' icon={<FaCheck size={25} color={"deepskyblue"} />} />
};

export default UserAcceptProduct;