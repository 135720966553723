import React, { useEffect, useState } from "react";
import Mispichoshelper from "../../utils/Mispichoshelper";
import Modals from "./Actions/Modals";
import OrderSummary from "./Info/Base";
import CompraSplitBox from "../../components/dashboard-elements/CompraSplitBox";
import { Modal } from "antd";
import OrderActions from "./Actions/Base";
import './base.css';
import Loadin from "../../components/componentsUI/Loading";
import ReturnOrderBox from "../../components/dashboard-elements/ReturnOrderBox";
import { AiOutlineLoading } from "react-icons/ai";
import TmsOrders from "./TmsOrder";
import { Row, Col } from "antd";


function OrderSelected({ compraIdSeleccionada }) {

  const [digitales, setDigitals] = useState(null)
  const [showModals, setShowModals] = useState({
    showProblemOrder: false,
    splitCompra: false,
    intervencion: false,
    delegarCompra: false,
    reprogramarCompra: false,
    editarCompra: false,
    buscarProducto: false,
    reembolso: false,
    altaDomi: false,
    cambiaDomi: false,
    manualPaid: false,
    generateLink: false,
    showFollowOrder: false,
    solucionarPedidosRojos: false,
    adjuntaModal: false,
    referModal: false,
    referModalBadExperience: false,
    adjuntaLoading: false,
    showNotes: false,
    showClientDataPayment: false,
    showCompleteOrder: false,
    showToContactByCs: false,
    showWizard: false,
    showNewWizard: false,
  })
  const [infoPaymentClient, setInfoPaymentClient] = useState(false)
  const [typeProblem, setTypeProblem] = useState(0)
  const [problemMessage, setProblemMessage] = useState(null)
  const [compra, setCompra] = useState()
  const [userHappiness, setHappiness] = useState()
  const [domicilios, setDom] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscaCompra(compraIdSeleccionada);
  }, [])


  const buscaCompra = (compra_id) => Mispichoshelper.getCompra(compra_id).then(cargaCompra);

  const cargaCompra = async (compra) => {
    var happiness = await Mispichoshelper.getUserHappiness({ user_id: compra.payload.cliente_id, com_id: compra.payload.compra_id });
    console.log('hagppiness', happiness);
    setCompra(compra.payload);
    console.log("COMPRA", compra)
    setHappiness(happiness.payload);
    getDomicilios(compra.payload.cliente_id)
  }

  const getDomicilios = (id) => {
    Mispichoshelper.getDomiciliosPerfil({ usuario_id: id }).then((response) => {
      console.log("Nuevos Dom", response.payload)
      setDom(response.payload);
    });
  }


  const refrescarCompra = (compra_id) => buscaCompra(compra_id);

  const hideAltaDomi = () => {
    getDomicilios(compra.cliente_id)
    toggleModal('altaDomi', false);
  }

  const toggleModal = (index, value) => {
    console.log("entro", index, "valores", value)
    setShowModals({ ...showModals, [index]: value })
  }

  const logisticType = (type) => {

    let title = !type ? 'Confirma cancelación de logistica terciarizada' : `Confirma la logística de tipo ${type}`;

    Modal.confirm({
      title: title,
      content: 'Cambio de tipo de logística',
      onOk: () => {
        var params = {
          com_id: [compra.compra_id],
          treggoState: type
        }
        Mispichoshelper.sendTreggoStatus(params).then(response => {
          if (response.status) {
            success(response)
            refrescarCompra(compra.compra_id)
          }
        })
      },
      okText: 'Si',
      cancelText: 'Volver'
    });


  }

  const externalLogistic = () => {

    Modal.confirm({
      title: 'Logística Externa',
      content: 'Confirma creación de una orden de logistica externa',
      onOk: () => {

        Mispichoshelper.externalLogistic.postCreateOrder({ com_id: compra.compra_id }).then(response => {
          if (response.status) {
            Modal.success({
              title: 'Orden de logistica externa creada!',
              content: response.payload.message,
              onOk() { refrescarCompra(compra.compra_id) },
            });
          } else if (response.payload.message) {
            Modal.error({
              title: 'Hubo un problema!',
              content: response.payload.message,
            });
          } else {
            Modal.error({
              title: 'Hubo un problema!',
              content: 'No se pudo crear la orden de logistica externa',
            });
          }
        })
      },
      okText: 'Si',
      cancelText: 'Volver'
    });

  }

  const informaResponse = (response) => {
    buscaCompra(compra.compra_id);
    if (response.payload.status) {
      success(response);
    } else {
      error(response);
    }
  }

  const success = (response) => {
    Modal.success({
      title: response.payload.title,
      content: response.payload.message,
      okText: 'Entendido'
    });
  }

  const error = (response) => {
    Modal.error({
      title: response.payload.title,
      content: response.payload.message,
    });
  }

  const sendProblemOrder = () => {
    if (!typeProblem) {
      Modal.error({
        title: 'Informacion!',
        content: 'Tenes que elegir una responsabilidad',
      });
      return
    }
    if (!problemMessage) {
      Modal.error({
        title: 'Informacion!',
        content: 'El campo de observaciones es obligatorio',
      });
      return
    }

    Modal.confirm({
      title: 'Pasar compra a "con problemas"?',
      content: 'La compra pasará a estado con problemas',
      onOk: () => Mispichoshelper.postProblemOrder(
        {
          com_id: compra.compra_id,
          message: problemMessage,
          typeProblem: typeProblem
        }).then(informaResponse),
      okText: 'Si, problemas',
      cancelText: 'Volver'
    });
  }

  const toggleCompraCompletada = () => {
    Modal.confirm({
      title: 'Desea completar la compra?',
      content: 'El pedido pasará a completado y entregado',
      onOk: () => Mispichoshelper.postCompraCompletada({ compra_id: compra.compra_id }).then(informaResponse),
      okText: 'Si, completar compra',
      cancelText: 'Volver'
    });
  }

  const sumarProducto = (cada_producto) => {
    if (cada_producto?.prv_id) {
      var un_producto = { cor_cantidad: 1, cor_precio: cada_producto.precio_minimo, prv_foto_link: cada_producto.pro_foto, prv_id: cada_producto.prv_id, prv_producto_nombre: cada_producto.producto };
      var aux = compra.productos;
      aux.push(un_producto);
      setCompra({ ...compra, productos: aux })
    } else if (cada_producto?.cop_id) {
      var un_producto = {
        cor_cantidad: 1,
        cor_precio: cada_producto.price,
        prv_foto_link: cada_producto?.image_url ? cada_producto.image_url : cada_producto.photo,
        cop_id: cada_producto.cop_id,
        prv_producto_nombre: cada_producto.full_name
      };
      var aux = compra.productos_combinados;
      aux.push(un_producto);
      setCompra({ ...compra, productos_combinados: aux })
    }
    toggleModal('buscarProducto', false);
  }

  const editaCantidadProducto = (indice_producto, nueva_cantidad, tipoIdentificador = null) => {
    var aux = { ...compra };
    // var canEdit = false;
    if (!tipoIdentificador || tipoIdentificador === 'prv_id') {
      aux.productos[indice_producto].cor_cantidad_nueva = nueva_cantidad;
    } else if (tipoIdentificador && tipoIdentificador == 'cop_id') {
      aux.productos_combinados[indice_producto].cor_cantidad_nueva = nueva_cantidad;
    }
    let productos = aux.productos.filter(item => (item.cor_cantidad_nueva > 0 || (item.cor_cantidad_nueva === undefined && item.cor_cantidad)));
    let productos_combinados = aux.productos_combinados.filter(item => (item.cor_cantidad_nueva > 0 || (item.cor_cantidad_nueva === undefined && item.cor_cantidad)));
    let total_disponible = productos.length + productos_combinados.length;
    if (total_disponible < 1) {
      setCompra({ ...compra });
      alert('No puede dejar la compra sin productos');

      return false;
    } else {
      setCompra(aux);
    }
  }

  const oportunityOrder = (full_reset = '') => {
    var title = `Estas por poner el pedido en oportunidad ${full_reset && 'reseteando todo'}, estás seguro?`;
    var content = 'Este pedido se hará visible para todos los petshops que puedan entregarlo.';
    Modal.confirm({
      title: title,
      content: content,
      onOk: () => Mispichoshelper.oportunityOrder({ com_id: compra.compra_id, full_reset: full_reset }).then(informaResponse),
      okText: 'Si',
      cancelText: 'Volver'
    });
  }

  const pauseOrder = () => {
    var title = !compra.com_en_pausa ? 'Estas por pausar la compra, estás seguro?' : "Estas por quitar la pausa a la compra, estás seguro?";
    var content = !compra.com_en_pausa ? 'confirmo que llamé al cliente para avisar la situación sobre este pedido' : 'Confirmo que voy a sacar la pausa de la compra';
    Modal.confirm({
      title: title,
      content: content,
      onOk: () => Mispichoshelper.pausaOrder({ com_id: compra.compra_id }).then(informaResponse),
      okText: 'Si',
      cancelText: 'Volver'
    });
  }

  // Adjuntar Digitales
  const addDigitales = () => {

    if (digitales) {

      toggleModal('adjuntaLoading', true)

      let params = {
        order: {
          num: compra.compra_id
        },
        digitales: digitales
      }

      Mispichoshelper.postOrderDigitales(params).then((response) => {

        toggleModal('adjuntaLoading', false)
        if (!response.status) {
          Modal.error({
            title: 'No se registraron los archivos',
            content: 'Hubo un problema al guardar',
          });
          return;
        }
        Modal.success({
          content: 'Archivos registrados',
          onOk() { window.location.reload(); },
        });

      })

    } else {
      toggleModal('adjuntaModal', false)
      toggleModal('adjuntaLoading', false)
    }
  }

  const handleFileChange = (event) => {
    console.log(event)
    const { target } = event;
    const { files } = target;
    console.log('files', files);
    if (files.length) {
      let filesData = [];
      for (var i = 0; i < files.length; i++) {
        var reader = new FileReader();
        /*
        let type = files[i].type;
        let name = files[i].name;
        let size = files[i].size; // bytes
    */
        reader.onload = event => {

          // --- Resize
          var image = new Image();
          image.onload = function (imageEvent) {

            var canvas = document.createElement('canvas'),
              max_size = 800,// TODO : pull max size from a site config
              width = image.width,
              height = image.height;

            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }

            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);

            var newImageData = canvas.toDataURL("image/jpeg", 80 / 100);

            filesData.push({
              base64: newImageData.split(',')[1]
            });

          }

          image.src = event.target.result;
        };
        reader.readAsDataURL(files[i]);
      }

      console.log('files data', filesData);
      setDigitals(filesData);
    }
  };


  // Colectar
  const resolveByCollectors = () => {
    Modal.confirm({
      title: `Está seguro de enviar el pedido a un colector?`,
      content: `El proceso de entrega es distinto`,
      onOk: () => Mispichoshelper.sendOrderToCollector({ order_id: compraIdSeleccionada }).then(response => {
        Modal.info({ title: 'Info', content: response.payload.message });
        buscaCompra(compraIdSeleccionada);
      }),
      okText: 'Si, colectar',
      cancelText: 'Cancelar'
    });

  }

  const toggleInfoClientPayment = () => {
    setLoading(true);
    Mispichoshelper.Accountability.getClientInfoPayment({ com_id: compraIdSeleccionada }).then(response => {
      try {
        if (response.status && response.payload.data !== undefined) {
          setInfoPaymentClient(response.payload.data);
          toggleModal('showClientDataPayment', true);
          setLoading(false);
        }
      } catch (error) {
        alert(error);
      }
    });
  }

  if (!compra) {
    return (
      <section style={{ minWidth: '100%', minHeight: '80vh' }} className={'Loadin'}>
        <AiOutlineLoading />
      </section>
    )
  }
  else {
    return (
      <div className='CompraSeleccionada'>
        <div>
          <OrderSummary refrescarCompra={refrescarCompra} compra={compra} userHappiness={userHappiness} toggleInfoClientPayment={toggleInfoClientPayment} infoPaymentClient={infoPaymentClient} domicilio={domicilios} isLoading={loading} />
          <Row gutter={[10, 10]}>
            <Col span={12}>

              {compra.compras_split && compra.compras_split.length !== 0 ? <CompraSplitBox refrescarCompra={refrescarCompra} compra={compra} /> : null}
              {compra.return_orders && compra.return_orders.length !== 0 ? <ReturnOrderBox refrescarCompra={refrescarCompra} compra={compra} /> : null}

            </Col>
            <Col span={12}>

              {/* TMS */}

              {/* compra.external_delivery_id ? <TmsOrders compra={compra} /> : null */}

            </Col>
          </Row>

        </div>

        <div>
          <OrderActions
            compra={compra}
            functions={{
              logisticType: logisticType,
              externalLogistic: externalLogistic,
              toggleModal: toggleModal,
              toggleCompraCompletada: toggleCompraCompletada,
              pauseOrder: pauseOrder,
              toggleInfoClientPayment: toggleInfoClientPayment,
              oportunityOrder: oportunityOrder,
              resolveByCollectors: resolveByCollectors,
            }}

          />
        </div>

        <Modals
          showModals={showModals}
          compra={compra}
          domicilios={domicilios}
          problemMessage={problemMessage}
          infoPaymentClient={infoPaymentClient}
          functions={{
            toggleModal: toggleModal,
            sendProblemOrder: sendProblemOrder,
            sumarProducto: sumarProducto,
            refrescarCompra: refrescarCompra,
            hideAltaDomi: hideAltaDomi,
            editaCantidadProducto: editaCantidadProducto,
            addDigitales: addDigitales,
            handleFileChange: handleFileChange,
            setTypeProblem: setTypeProblem,
            setProblemMessage: setProblemMessage
          }}
        />
      </div>
    );
  }

}

export default OrderSelected;



