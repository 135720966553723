import { Button, Col, InputNumber, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FaBoxOpen, FaWindowClose } from "react-icons/fa";
import { constants } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";

const ProductListWithQty = ({ products = [], handleHistorical, confusedProducts = false, orderNotDelivered = false }) => {

    const {
        flow,
        setFlow,
    } = useContext(WizardContext);

    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        if (orderNotDelivered) {
            autoCompleteProductQuantities();
        }
    }, [])

    const autoCompleteProductQuantities = () => {
        const missingOrderProducts = products.map(p => ({ prv_id: p.prv_id, quantity: p.cor_cantidad }));
        setFlow(flow => ({ ...flow, products: missingOrderProducts }));
        handleHistorical(constants.PARAM_PRODUCTS, `Productos afectados cargados:`, "Sí");
    }

    const handleProductSelection = (value) => {
        confusedProducts && window.scrollTo(0, 0)
        const [prv_id, qty] = value.split("-").map(Number);

        setSelectedProducts(prev => {
            let newProducts;

            if (confusedProducts) {
                if (!qty || qty === 0) return;

                const currentProducts = flow.conditions?.different_products || [];
                const productToUpdate = currentProducts.find(p => p.prv_id === prv_id);

                if (productToUpdate) {
                    const updatedProducts = currentProducts.map(p => {
                        if (p.prv_id === prv_id) {
                            return {
                                ...p,
                                quantity: qty
                            };
                        }

                        return p;
                    });

                    setFlow(currentFlow => ({
                        ...currentFlow,
                        conditions: {
                            ...currentFlow.conditions,
                            different_products: updatedProducts
                        }
                    }));

                    handleHistorical(constants.PARAM_DIFFERENT_PRODUCTS, `Productos a cambiar cargados:`, "Sí");
                    return updatedProducts;
                }
            } else {

                if (qty > 0) {
                    const existingProductIndex = prev.findIndex(item => item.prv_id === prv_id);

                    if (existingProductIndex >= 0) {
                        newProducts = [...prev];
                        newProducts[existingProductIndex].quantity = qty;
                    } else {
                        newProducts = [...prev, { prv_id, quantity: qty }];
                    }

                    handleHistorical(constants.PARAM_PRODUCTS, `Productos afectados cargados:`, "Sí");
                } else {
                    newProducts = prev.filter(item => item.prv_id !== prv_id);
                    if (newProducts.length === 0) {
                        handleHistorical(constants.PARAM_PRODUCTS, undefined);
                    } else {
                        handleHistorical(constants.PARAM_PRODUCTS, `Productos afectados cargados:`, "Sí");
                    }
                }
                setFlow(flow => ({ ...flow, products: newProducts }));
            }

            return newProducts;
        });
    };

    useEffect(() => {
        if (flow?.products?.length > 0) {
            const initialSelectedProducts = flow.products
                .filter(p => p.quantity > 0)
                .map(p => ({ prv_id: p.prv_id, quantity: p.quantity }));
            setSelectedProducts(initialSelectedProducts);
        } else {
            setSelectedProducts([]);
        }
    }, [flow?.products]);

    const handleProductValue = (product) => {
        if (product) {
            const selectedProd = selectedProducts?.find(p => p.prv_id === product.prv_id);
            if (selectedProd) {
                return `${product.prv_id}-${selectedProd.quantity}`;
            }

            const flowProduct = flow?.products?.find(p => p.prv_id === product.prv_id);
            if (flowProduct && flowProduct.quantity > 0) {
                return `${product.prv_id}-${flowProduct.quantity}`;
            }
        }

        return undefined;
    };

    const removeItemFromProductList = (product) => {
        const filterProducts = products.filter(p => p.prv_id !== product.prv_id);

        setSelectedProducts(filterProducts);

        setFlow(flow => ({ ...flow, conditions: ({ ...flow.conditions, different_products: filterProducts }) }));

        handleHistorical(constants.PARAM_DIFFERENT_PRODUCTS, `Productos afectados cargados:`, filterProducts.length > 0 ? "Sí" : undefined);
    }

    const handleBorderColor = (product) => {
        return `2px solid ${(selectedProducts?.find(p => p.prv_id === product.prv_id)?.quantity <= 0 || flow?.conditions?.different_products?.find(p => p.prv_id === product.prv_id)?.quantity <= 0) ? 'red' : 'deepskyblue'}`
    }

    return (
        <>
            <Row className="d-flex align-items-center pb-2">
                {!confusedProducts && <>
                    <FaBoxOpen size={25} color={"deepskyblue"} />
                    <strong style={{ marginLeft: 5, fontSize: 14 }}>Seleccione los productos afectados</strong>
                </>}
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 10, paddingTop: 10, flexWrap: 'wrap' }} >
                {products.map((product) => (
                    <Col key={product.prv_id} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, marginTop: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '30%' }}>
                            {product.photo_desktop && <img src={product.photo_desktop} style={{ width: 50, height: 90, borderRadius: 5, padding: 5 }} alt={product.prv_producto_nombre} />}
                        </div>
                        <Col style={{ width: '70%', position: 'relative' }}>
                            <span style={{ fontSize: 12 }}>{product.prv_producto_nombre}</span>
                            {!confusedProducts ?
                                <>
                                    <p style={{ fontSize: 12 }}>Solicitado {product.quantity}</p>
                                    <p style={{ fontSize: 12 }}>Faltan</p>
                                    <Select
                                        style={{ width: '100%', position: 'absolute', bottom: 0 }}
                                        placeholder="Cantidad"
                                        value={handleProductValue(product)}
                                        onChange={(value) => handleProductSelection(value)}
                                    >
                                        <Select.Option key={`option-${product.prv_id}-0`} value={`${product.prv_id}-0`}>0</Select.Option>
                                        {product.cor_cantidad > 0 && Array.from({ length: product.cor_cantidad }, (_, index) => (
                                            <Select.Option key={`option-${product.prv_id}-${index + 1}`} value={`${product.prv_id}-${index + 1}`}>
                                                {index + 1}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </>
                                : <Row>
                                    <span style={{ fontWeight: 'bold' }}> ${product.price} </span>
                                    <InputNumber
                                        style={{ border: handleBorderColor(product) }}
                                        placeholder="Cantidad"
                                        contentEditable={false}
                                        defaultValue={0}
                                        value={flow?.conditions?.different_products.find(p => p.prv_id === product.prv_id)?.quantity ?? selectedProducts.find(p => p.prv_id === product.prv_id)?.quantity}
                                        onChange={(value) => handleProductSelection(`${product.prv_id}-${value}`)} />

                                    <Button onClick={() => removeItemFromProductList(product)} style={{ border: 'none' }}>
                                        <FaWindowClose size={20} color={"red"} style={{ marginLeft: 10 }} />
                                    </Button>
                                </Row>
                            }
                        </Col>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default ProductListWithQty;