import ProductListWithQty from "../Steps/ProductListWithQty";
import OrderReceipt from "../Steps/OrderReceipt";
import RefundMethod from "../Steps/RefundMethod";
import ScheduleOrderSolution from "../Components/ScheduleOrderSolution";
import UserBlame from "../Steps/UserBlame";
import UserSolution from "../Steps/UserSolution";
import { useCallback, useContext, useEffect, useState } from "react";
import UserPaymentInfo from "../Steps/UserPaymentInfo";
import useWizardReset, { constants } from "../Utils";
import WizardLastStep from "../Steps/WizardLastStep";
import { Modal, Row } from "antd";
import Historical from "../Components/Historical";
import Controls from "../Components/Controls";
import { WizardContext } from "../Context/WizardProvider";

const OrderNotDelivered = ({ order }) => {

    const {
        flow,
        setFlow,
        solutions,
        getSolutions,
        resolveWizard,
        resetWizardParams,
        handleHistorical,
        historical,
        images,
        wizardIndex,
        setWizardIndex,
        steps,
        setSteps
    } = useContext(WizardContext);

    const [clientFault, setClientFault] = useState({
        visible: false,
        message: ''
    });

    useEffect(() => {
        handleGetNewSolutions();
    }, [flow]);

    const historicalFunction = useCallback((key, title = '', valueSelected) => {
        handleHistorical(key, title, valueSelected);

        handleResetWizardIfParamsChanged(valueSelected?.type ?? valueSelected, key, {
            fault: true,
            total_order_payed: true,
        });
    }, [wizardIndex, steps?.length, setFlow]);

    useEffect(() => {
        handleFlowStepsConfig();
    }, [flow, solutions, historicalFunction, order]);

    const handleGetNewSolutions = useCallback(() => {
        isClientResponsible();

        const { conditions } = flow || {};
        if (conditions) {
            const {
                [constants.PARAM_FAULT]: fault,
                [constants.PARAM_TOTAL_ORDER_PAID]: total_order_payed,
                [constants.PARAM_RECEIPT]: receipt
            } = conditions;

            if (
                fault !== undefined &&
                total_order_payed !== undefined &&
                receipt !== undefined &&
                flow[constants.PARAM_PRODUCTS]?.length > 0
            ) {

                const solParams = {
                    fault,
                    total_order_payed,
                    receipt
                };

                getSolutions(order.compra_id, 'not_delivered', solParams);
            }
        }
    }, [flow, getSolutions]);

    const isClientResponsible = () => {
        if (flow?.conditions?.fault === constants.CLIENT_FAULT || flow?.conditions?.receipt) {
            setClientFault({
                visible: true,
                message: 'Existe el comprobante de entrega, por lo que el cliente es responsable.'
            });
            resetWizardParams();
        }
    };

    const handleResetWizardIfParamsChanged = useWizardReset(flow, setClientFault, resetWizardParams);

    const handleFlowStepsConfig = () => {
        const baseSteps = [
            <OrderReceipt
                flow={flow}
                setFlow={setFlow}
                order={order}
                images={images}
                handleHistorical={historicalFunction}

            />,
            <UserBlame
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
            />,
            <UserPaymentInfo
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
            />,
            <ProductListWithQty
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
                products={order.allProductByPrv}
                orderNotDelivered={true}
            />,
            <UserSolution
                flow={flow}
                setFlow={setFlow}
                handleHistorical={historicalFunction}
                solutions={solutions}
            />,
        ];

        const currentSolution = flow.conditions?.solution;
        let finalStep;

        if (currentSolution === constants.COMPLETE_ORDER_SOLUTION) {
            finalStep = (
                <ScheduleOrderSolution
                    flow={flow}
                    setFlow={setFlow}
                    handleHistorical={historicalFunction}
                    order={order}
                />
            );
        } else {
            finalStep = (
                <RefundMethod
                    flow={flow}
                    setFlow={setFlow}
                    handleHistorical={historicalFunction}
                />
            );
        }

        setSteps([...baseSteps, finalStep, <WizardLastStep order={order} resolveWizard={resolveWizard} />]);
    };

    return <>
        <Modal title="⚠️ Atención" visible={clientFault.visible} footer={null} onCancel={() => setClientFault({
            visible: false,
            message: ''
        })}>
            <p>{clientFault.message}</p>
        </Modal>

        <Historical historical={historical} wizardIndex={wizardIndex} />

        <Row style={{ width: '100%', border: '1px solid #ccc', borderRadius: 5, padding: 10, marginTop: 10 }}>
            {steps[wizardIndex]}
        </Row>

        <Controls
            wizardIndex={wizardIndex}
            setWizardIndex={setWizardIndex}
            steps={steps}
            historical={historical}
        />
    </>
};

export default OrderNotDelivered;