import { useContext, useEffect, useState } from "react";
import { FaCheck, FaCheckDouble, FaDotCircle, FaThinkPeaks } from "react-icons/fa";
import BooleanSelector from "../Components/BooleanSelector";
import { constants } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";

const UserBlame = ({ handleHistorical }) => {

    const {
        setFlow
    } = useContext(WizardContext);

    const handleSelection = (value) => {
        setFlow(flow => ({ ...flow, conditions: { ...flow.conditions, [constants.PARAM_FAULT]: value ? constants.PET_FAULT : constants.CLIENT_FAULT } }));
        handleHistorical(constants.PARAM_FAULT, '¿El cliente tiene razón?', value ? 'Sí' : 'No');
    };

    return <BooleanSelector onChange={handleSelection} title='¿El cliente tiene razón?' icon={<FaCheck size={25} color={"deepskyblue"} />} />
};

export default UserBlame;