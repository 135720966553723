import { Collapse, Row, Tooltip } from "antd";
import { FaDotCircle, FaListAlt } from "react-icons/fa";

const { Panel } = Collapse;

const Historical = ({ historical = [], wizardIndex }) => {
    return (
        <Collapse defaultActiveKey={[]} style={{ marginTop: 10 }} expandIcon={({ isActive }) => <Tooltip title="Listado histórico de todas las opciones que fueron seleccionadas en el flujo del reclamo.">
            <FaListAlt size={15} color={isActive ? "deepskyblue" : 'grey'} />
        </Tooltip>}>
            <Panel header={`Historial de opciones seleccionadas: (${historical.length})`} style={{ fontSize: 15 }} key="1">
                <Row >
                    {historical?.length > 0 ?
                        historical.map((item, index) => (
                            <div
                                className="d-flex align-items-center"
                                style={{
                                    gap: 4,
                                    padding: 5,
                                    margin: 3,
                                }}
                                key={index}
                            >
                                <FaDotCircle size={10} color={index === wizardIndex ? "deepskyblue" : 'grey'} />
                                <p
                                    style={{
                                        fontWeight: index === wizardIndex ? "bold" : "normal",
                                        fontSize: 12,
                                        color: 'grey',
                                    }}
                                >
                                    {item.title} {item.value === true ? 'Si' : item.value === false ? 'No' : item.value}
                                </p>
                            </div>
                        )) : <p style={{ fontSize: 12 }}>No se han seleccionado opciones</p>}
                </Row>
            </Panel>
        </Collapse>
    );
};

export default Historical;
