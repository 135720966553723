import { Row } from "antd";
import { FaBoxOpen } from "react-icons/fa";
import SearchProducts from "../../componentsCustomerService/Order/Actions/SearchProducts";
import ProductListWithQty from "./ProductListWithQty";
import { useContext } from "react";
import { WizardContext } from "../Context/WizardProvider";

const SearchProds = ({ handleHistorical, order }) => {

    const {
        flow,
        setFlow
    } = useContext(WizardContext);

    const handleConfusedProducts = (value) => {
        const prv_id = value.prv_id;
        const qty = 0;

        setFlow(flow => ({
            ...flow, conditions: {
                ...flow.conditions,
                different_products: [
                    ...flow?.conditions?.different_products ?? [],
                    {
                        prv_id: prv_id,
                        quantity: qty,
                        photo_desktop: value.pro_foto,
                        price: value.precio_minimo,
                        prv_producto_nombre: value.pro_nombre_completo
                    }
                ]
            }
        }));
    };

    return <Row style={{ maxHeight: 400, overflowY: 'auto' }}>
        <strong style={{ marginLeft: 5, fontSize: 14 }}><FaBoxOpen size={25} color={"deepskyblue"} /> Seleccione los productos equivocados que recibió el cliente</strong>
        <SearchProducts
            sumarProducto={handleConfusedProducts}
            compra={order}
            showTabCombined
            excluir_no_se_vende />
        <br />
        <br />
        <ProductListWithQty
            products={flow?.conditions?.different_products ?? []}
            flow={flow}
            setFlow={setFlow}
            handleHistorical={handleHistorical}
            confusedProducts={true} />
    </Row>
};

export default SearchProds;