import React from 'react'
import OrderUserAddress from '../../../components/Usuarios-elments/AltaUsuariosFormDomi'
import { MispichosVars } from '../../../utils/Mispichoshelper'
import { Modal, Button, Radio, Col, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import ChangeAddress from './ChangeAddress'
import ChangeDeliveryTime from './ChangeDeliveryTime'
import Delegate from './Delegate'
import EditProducts from './EditProducts'
import FixRedOrder from './FixRedOrder'
import Follow from './Follow'
import Intervention from './Intervention'
import GenerateLink from './GenerateLink'
import ManualPaid from './ManualPaid'
import Notes from './Notes'
import Refund from './Refund'
import OrderSplit from './Split'
import SearchProducts from './SearchProducts'
import moment from 'moment';
import Completeorder from './CompleteOrder'
import NewUserCheckingAccount, { CHECKING_ACCOUNT_BAD_EXPERIENCE } from './NewUserCheckingAcount'
import ToContactCS from './ToContactCS'
import ContactedByCS from './ContactedByCS'
import WizardOrderClaim from '../../../components/compra/WizardReclamo'
import WizardModal from '../../../componentsWizard/WizardModal'
import { WizardProvider } from '../../../componentsWizard/Context/WizardProvider'
export default function Modals({ showModals, compra, domicilios, problemMessage, functions, infoPaymentClient }) {

    return (
        <>
            {showModals.showClientDataPayment &&
                <Modal
                    title="Info de mercado pago del cliente"
                    className='p-0 w-50'
                    visible={showModals.showClientDataPayment}
                    onOk={() => functions.toggleModal('showClientDataPayment', false)}
                    onCancel={() => functions.toggleModal('showClientDataPayment', false)}
                >
                    <div>
                        <div>Nro Operación: {infoPaymentClient.id}</div>
                        <div className='MT1'>Nombre Cliente: {infoPaymentClient.payer_name}</div>
                        <div className='MT1'>Apellido Cliente: {infoPaymentClient.payer_last_name}</div>
                        <div className='MT1'>DNI Tarjeta: {infoPaymentClient.card_holder_identification}</div>
                        <div className='MT1'>Nombre En Tarjeta: {infoPaymentClient.card_holder_name}</div>
                        <div className='MT1'>Nro Tarjeta: {infoPaymentClient.card_digits}</div>
                        <div className='MT1'>Estado del pago: {infoPaymentClient.status}</div>
                        <div className='MT1'>Progreso del estado: {infoPaymentClient.status_progress}</div>
                        <div className='MT1'>Última modificación: {moment(infoPaymentClient.date_last_update).format('L')}</div>
                    </div>
                </Modal>
            }

            {showModals.solucionarPedidosRojos &&
                <Modal
                    title="Solucionar Pedidos en Rojo"
                    className='p-0'
                    style={{ minWidth: '60vw' }}
                    visible={showModals.solucionarPedidosRojos}
                    onOk={() => functions.toggleModal('solucionarPedidosRojos', false)}
                    onCancel={() => functions.toggleModal('solucionarPedidosRojos', false)}
                >
                    <FixRedOrder compra={compra} />
                </Modal>
            }

            {showModals.showProblemOrder &&
                <Modal
                    title="Compra con problemas"
                    className='p-0'
                    style={{ minWidth: '60vw' }}
                    visible={showModals.showProblemOrder}
                    onOk={functions.sendProblemOrder}
                    onCancel={() => functions.toggleModal('showProblemOrder', false)}
                >
                    <div>
                        <Row>
                            <Col lg='5'>
                                <h6 className='MB1'>Cliente tuvo el problema</h6>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_CLIENTE_NO_RESPOND} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>El cliente no atendía el teléfono</span>
                                </Radio>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_CLIENTE_NO_RESPOND} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>El cliente no acepta pedido</span>
                                </Radio>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_CLIENTE_OTRO} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Otros</span>
                                </Radio>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_CLIENTE_SE_CONFUNDE_PRODUCTOS} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>El cliente se confunde de productos</span>
                                </Radio>
                            </Col>
                            <Col lg='5'>
                                <h6 className='MB1'>Petshop tuvo el problema</h6>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_PETSHOP_ACEPTA_Y_NO_VA_ENTREGAR} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Pedido no llego</span>
                                </Radio>
                                <Radio name='tipo_problema' value={120} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Producto confundido</span>
                                </Radio>
                                <Radio name='tipo_problema' value={119} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Producto roto</span>
                                </Radio>
                                <Radio name='tipo_problema' value={118} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Producto vencido</span>
                                </Radio>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_PETSHOP_PRODUCTO_MAL_ESTADO} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Producto en mal estado  </span>
                                </Radio>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_PETSHOP_PEDIDO_INCOMPLETO} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Pedido incompleto</span>
                                </Radio>
                                <Radio name='tipo_problema' value={121} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>No entrego factura </span>
                                </Radio>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_PETSHOP_OTRO} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Otro</span>
                                </Radio>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='10'>
                                <h6 className='MB1'>Mispichos tuvo el problema</h6>
                                <Radio name='tipo_problema' value={MispichosVars.PROBLEMA_OTRO_ID} onChange={evt => functions.setTypeProblem(evt.target.value)}>
                                    <span>Qué nos pasó?</span>
                                </Radio>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='10'>
                                <h5 className='MT1 MB1'>Detallar el inconveniente</h5>
                                <TextArea
                                    className='campoTextarea'
                                    placeholder="Detalles del inconveniente"
                                    autosize={{ minRows: 6, maxRows: 6 }}
                                    value={problemMessage}
                                    onChange={(message) => functions.setProblemMessage(message.target.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }

            {showModals.buscarProducto &&
                <Modal
                    title="Agregar otro producto a la lista"
                    style={{ minWidth: '50vw' }}
                    visible={showModals.buscarProducto}
                    onOk={() => functions.toggleModal('buscarProducto', false)}
                    onCancel={() => functions.toggleModal('buscarProducto', false)}
                >
                    <SearchProducts sumarProducto={functions.sumarProducto} compra={compra} get_combinados={true} showTabCombined={true} />
                </Modal>
            }

            {showModals.manualPaid &&
                <ManualPaid refrescarCompra={functions.refrescarCompra} manualPaid={showModals.manualPaid} compra={compra} hideManualPaid={functions.toggleModal} />
            }

            {showModals.generateLink &&
                <GenerateLink visible={showModals.generateLink} toggleModal={functions.toggleModal} compra={compra} />
            }

            {showModals.showFollowOrder &&
                <Follow refrescarCompra={functions.refrescarCompra} compra={compra} showFollowOrder={showModals.showFollowOrder} hideFollowOrder={functions.toggleModal} />
            }

            {showModals.altaDomi &&
                <OrderUserAddress compra={compra} showAltaDomicilio={showModals.altaDomi} hideAltaDomicilio={functions.hideAltaDomi} usuarioId={compra.cliente_id} />
            }

            {showModals.cambiaDomi &&
                <ChangeAddress compra={compra} toggleModal={functions.toggleModal} refrescarCompra={functions.refrescarCompra} showCambiaDomicilio={showModals.cambiaDomi} domicilios={domicilios} />
            }

            {showModals.splitCompra &&
                <OrderSplit compra={compra} showSplitCompra={showModals.splitCompra} refrescarCompra={functions.refrescarCompra} hideSplitCompra={functions.toggleModal} />
            }

            {showModals.intervencion &&
                <Intervention compra={compra} refrescarCompra={functions.refrescarCompra} showIntervencion={showModals.intervencion} hideIntervencion={functions.toggleModal} />
            }

            {showModals.reembolso &&
                <Refund compra={compra} refrescarCompra={functions.refrescarCompra} hideReembolso={functions.toggleModal} showReembolso={showModals.reembolso} />
            }

            {showModals.delegarCompra &&
                <Delegate delegarCompra={showModals.delegarCompra} refrescarCompra={functions.refrescarCompra} hideDelegar={functions.toggleModal} compra={compra} />
            }
            {showModals.editarCompra &&
                <EditProducts editaCantidadProducto={functions.editaCantidadProducto} editarCompra={showModals.editarCompra} refrescarCompra={functions.refrescarCompra} toggleModal={functions.toggleModal} compra={compra} />
            }
            {showModals.reprogramarCompra &&
                <ChangeDeliveryTime refrescarCompra={functions.refrescarCompra} showReprogramar={showModals.reprogramarCompra} hideReprogramar={functions.toggleModal} compra={compra} />
            }

            {showModals.adjuntaModal &&
                <Modal
                    title="Adjuntar Digitales"
                    visible={showModals.adjuntaModal}
                    onCancel={() => functions.toggleModal('adjuntaModal', false)}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={[
                        <Button key="submit" type="primary" loading={showModals.adjuntaLoading} onClick={() => functions.addDigitales()}>
                            OK
                        </Button>,
                    ]}
                >
                    <input
                        id="car"
                        type="file"
                        multiple={true}
                        accept={"image/*"}
                        capture="camera"
                        onChange={(e) => functions.handleFileChange(e)}
                    />
                </Modal>
            }
            {showModals.referModal &&
                <NewUserCheckingAccount compra={compra} usuarioId={compra.cliente_id} showRefer={showModals.referModal} loading={showModals.adjuntaLoading} toggleModal={() => functions.toggleModal('referModal', false)} />
            }

            {showModals.referModalBadExperience &&
                <NewUserCheckingAccount compra={compra} usuarioId={compra.cliente_id} showRefer={showModals.referModalBadExperience} loading={showModals.adjuntaLoading} toggleModal={() => functions.toggleModal('referModalBadExperience', false)} action={CHECKING_ACCOUNT_BAD_EXPERIENCE} />
            }

            {showModals.showNotes &&
                <Modal
                    title="Notas Internas"
                    visible={showModals.showNotes}
                    onOk={() => functions.toggleModal('showNotes', false)}
                    onCancel={() => functions.toggleModal('showNotes', false)}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={null}
                >
                    <Notes compra={compra} toggleModal={functions.toggleModal} />
                </Modal>
            }

            {showModals.showCompleteOrder &&
                <Completeorder compra={compra} showCompleteOrder={showModals.showCompleteOrder} refrescarCompra={functions.refrescarCompra} close={functions.toggleModal} />
            }

            {showModals.showToContactByCs &&
                <ToContactCS compra={compra} showModals={showModals} refrescarCompra={functions.refrescarCompra} close={functions.toggleModal} />
            }
            {showModals.showContactedByCs &&
                <ContactedByCS compra={compra} showModals={showModals} refrescarCompra={functions.refrescarCompra} close={functions.toggleModal} />
            }

            {showModals.showNewWizard &&
                <WizardProvider>
                    <WizardModal showModals={showModals} functions={functions} order={compra} />
                </WizardProvider>
            }

            {showModals.showWizard &&
                <WizardOrderClaim order={compra} refreshPage={functions.refrescarCompra} toggle={functions.toggleModal} />
            }
        </>
    )
}
