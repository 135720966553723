import { Button, Col, Modal, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { FaReceipt } from "react-icons/fa";
import WizardLoading from "../Components/WizardLoading";
import { WizardContext } from "../Context/WizardProvider";

const WizardLastStep = ({ order }) => {

    const {
        resolveWizard
    } = useContext(WizardContext);

    const [resolveWizardVisible, setResolveWizardVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [summaryLeyend, setSummaryLeyend] = useState('');

    const GET_SUMMARY = true;
    const RESOLVE = false;

    useEffect(() => {
        getSummaryMessages();
    }, []);

    const getSummaryMessages = async () => {
        const messages = await resolveWizard(order.compra_id, GET_SUMMARY)
        setSummaryLeyend(messages);
        setLoading(false);
    }

    const handleResolveWizard = async () => {
        await resolveWizard(order.compra_id, RESOLVE);
        setResolveWizardVisible(false);
    }

    if (loading) return <Row>
        <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
            <WizardLoading />
        </Col>
    </Row>

    return <Row>
        <Modal title="🚨 Atención"
            visible={resolveWizardVisible}
            onOk={handleResolveWizard}
            okText="Enviar"
            cancelText="Cancelar"
            cancelButtonProps={{ style: { border: 'none' } }}
            onCancel={() => setResolveWizardVisible(false)}>
            <p>¿Estás seguro de que deseas enviar el reclamo?</p>
        </Modal>

        <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
            {summaryLeyend && <p style={{ backgroundColor: 'rgb(114, 114, 114)', padding: 25, borderRadius: 15, color: 'white' }}>Al finalizar el reclamo:  {summaryLeyend}</p>}
            <Button loading={loading} type="primary" onClick={() => setResolveWizardVisible(true)} className="d-flex align-items-center" style={{ gap: 10 }}><FaReceipt /> Enviar solicitud</Button>
        </Col>
    </Row>
};

export default WizardLastStep;