import { useContext, useEffect, useState } from "react";
import { FaMoneyBillWave } from "react-icons/fa";
import BooleanSelector from "../Components/BooleanSelector";
import { WizardContext } from "../Context/WizardProvider";
import { constants } from "../Utils";

const UserPaymentInfo = ({ order, handleHistorical }) => {

    const {
        flow,
        setFlow,
        forcePaymentInfoValue,
    } = useContext(WizardContext);

    const [optionSelected, setOptionSelected] = useState(null);

    useEffect(() => {
        forceValueIfOrderWasPaidInCreditCard();
    }, [flow?.conditions?.solution]);

    const forceValueIfOrderWasPaidInCreditCard = () => {
        if (forcePaymentInfoValue && !flow?.conditions?.total_order_payed) {
            setOptionSelected(forcePaymentInfoValue);
            handleSelection(forcePaymentInfoValue);
        }
    };

    const handleSelection = (value) => {
        setFlow(flow => ({ ...flow, conditions: { ...flow.conditions, total_order_payed: value } }));
        setOptionSelected(value === true ? 'Si' : 'No');
        handleHistorical(constants.PARAM_TOTAL_ORDER_PAID, 'Orden pagada en su totalidad: ', value);
    };

    return <BooleanSelector value={optionSelected ?? flow.conditions.total_order_payed} disabled={forcePaymentInfoValue ? true : false} onChange={handleSelection} title={'¿El cliente abonó el total del pedido al repartidor?'} icon={<FaMoneyBillWave size={20} color={"deepskyblue"} />} />
};

export default UserPaymentInfo;