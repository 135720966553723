import { Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FaDotCircle } from "react-icons/fa";
import { constants } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";

const RefundMethod = ({ handleHistorical }) => {

    const {
        flow,
        setFlow,
    } = useContext(WizardContext);

    const [optionSelected, setOptionSelected] = useState(null);

    useEffect(() => {
        if (flow?.conditions?.solution !== constants.COMPLETE_ORDER_SOLUTION)
            handleRefundSolution(flow.conditions?.solution);
    }, [flow?.conditions?.solution]);

    const handleRefundSolution = (currentSolution) => {
        if (flow.conditions?.payment_type) return;

        if (currentSolution === constants.PARTIAL_CARD_REFUND || currentSolution === constants.FULL_CARD_REFUND)
            handleSelection(constants.CREDIT_CARD);
        else if (currentSolution === constants.PARTIAL_CREDIT_REFUND || currentSolution === constants.FULL_CREDIT_REFUND || currentSolution === constants.ACCEPT_ISSUE_AND_REWARD_WITH_CREDIT)
            handleSelection(constants.PICHO_PESOS);
        else
            handleSelection(null);
    };

    const handleSelection = (value) => {
        setFlow(flow => ({ ...flow, conditions: { ...flow.conditions, payment_type: value } }));
        setOptionSelected(value);
        handleHistorical(constants.PARAM_PAYMENT_TYPE, 'Método de devolución: ', value === constants.PICHO_PESOS ? 'Crédito' : 'Tarjeta');
    };

    return <div style={{ marginTop: 10 }}>
        <Row className="d-flex align-items-center pb-2" style={{ gap: 5 }}>
            <FaDotCircle color={"deepskyblue"} />
            <strong style={{ fontSize: 14 }}>Método de devolución</strong>
        </Row>
        <Select style={{ width: '100%' }} onChange={handleSelection} value={optionSelected} disabled={flow?.conditions?.solution ? true : false} >
            <Select.Option value={constants.PICHO_PESOS}>Picho pesos</Select.Option>
            <Select.Option value={constants.CREDIT_CARD}>Tarjeta</Select.Option>
        </Select>
    </div>
};

export default RefundMethod;