import { Button, Col, Row } from "antd";
import { FaExchangeAlt, FaFileUpload, FaTrash, FaUpload } from "react-icons/fa";
import ReactImageUploading from "react-images-uploading";
import { constants } from "../Utils";
import { useContext, useState } from "react";
import { WizardContext } from "../Context/WizardProvider";

const FileUploader = ({ handleHistorical }) => {
    const maxNumber = 3;

    const {
        setImages,
        images
    } = useContext(WizardContext);

    const [proofImages, setProofImage] = useState([...images]);

    const onChange = (imageList, addUpdateIndex) => {
        imageList.filter(item => {
            let reader = new FileReader();
            reader.readAsDataURL(item.file);

            reader.onloadend = async function () {
                var image = new Image();
                image.onload = function () {

                    var canvas = document.createElement('canvas'),
                        max_size = 800,
                        width = image.width,
                        height = image.height;

                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);

                    var newImageData = canvas.toDataURL("image/jpeg", 80 / 100);
                    item.base64 = newImageData.split(',')[1]
                    item.dataURL = newImageData

                }
                image.src = reader.result;
            }
        })
        setImages(imageList);
        setProofImage(imageList);
        handleHistorical(constants.IMAGE_PROOF, 'Foto evidencia del cliente: ', imageList ? 'cargada' : 'No');
    };

    return <Row style={{ marginTop: 10 }} >

        <ReactImageUploading
            multiple
            value={proofImages}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => (
                <Row className="upload__image-wrapper" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Col span={12} >
                        <Row className="d-flex align-items-center pb-2" style={{ gap: 5 }}>
                            <FaUpload size={20} color={"deepskyblue"} />
                            <strong style={{ fontSize: 12 }}>Subir imagen evidencia</strong>
                        </Row>
                        <Button
                            type={isDragging ? 'success' : 'primary'}
                            style={{ marginRight: 5 }}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            <FaFileUpload size={14} style={{ marginRight: 5 }} />
                            Subir / arrastar imagen
                        </Button>
                        <Button onClick={onImageRemoveAll} type={'danger'} style={{ marginRight: 5 }}>
                            <FaTrash size={14} style={{ marginRight: 5 }} />
                            Eliminar imagen
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Row className="image-item pt-2 pb-2 d-flex align-items-center justify-content-start" style={{ gap: 10 }}>
                            {imageList?.map((image, index) => (
                                <Row key={index}>
                                    <img src={image['data_url']} alt="" width="100" />
                                    <div className="image-item__btn-wrapper pt-1">
                                        <Button onClick={() => onImageUpdate(index)} type={'primary'} style={{ marginRight: 5 }}>
                                            <FaExchangeAlt size={14} style={{ marginRight: 5 }} />
                                        </Button>
                                        <Button onClick={() => onImageRemove(index)} type={'danger'} style={{ marginRight: 5 }}>
                                            <FaTrash size={14} style={{ marginRight: 5 }} />
                                        </Button>
                                    </div>
                                </Row>
                            ))}
                        </Row>
                    </Col>
                </Row>
            )}
        </ReactImageUploading>
    </Row>
};

export default FileUploader;