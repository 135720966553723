import { Modal, Row } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import Controls from "../Components/Controls";
import Historical from "../Components/Historical";
import ProductListWithQty from "../Steps/ProductListWithQty";
import RefundMethod from "../Steps/RefundMethod";
import ScheduleOrderSolution from "../Components/ScheduleOrderSolution";
import UserBlame from "../Steps/UserBlame";
import UserPaymentInfo from "../Steps/UserPaymentInfo";
import UserSolution from "../Steps/UserSolution";
import useWizardReset, { constants, shouldDoSolutionOrder } from "../Utils";
import WizardLastStep from "../Steps/WizardLastStep";
import OrderReceipt from "../Steps/OrderReceipt";
import FlowBodyRender from "../Components/FlowBodyRender";
import { WizardContext } from "../Context/WizardProvider";

const Incomplete = ({ order }) => {

    const {
        getSolutions,
        resetWizardParams,
        handleHistorical,
        wizardIndex,
        handleUserPaymentInfoRendering,
        steps,
        setSteps,
        solutions,
        flow,
        setFlow
    } = useContext(WizardContext);

    const [clientFault, setClientFault] = useState({
        visible: false,
        message: ''
    });

    const handleWizardFlowProductsReset = (key, valueSelected) => {
        if (key === constants.PARAM_SOLUTION) {
            setFlow(prevFlow => ({
                ...prevFlow,
                products: [],
                conditions: {
                    ...prevFlow.conditions,
                    ...(key === constants.PARAM_SOLUTION && { solution: valueSelected })
                }
            }));
        }
    };


    const handleResetWizardIfParamsChanged = useWizardReset(flow, setClientFault, resetWizardParams);

    const historicalFunction = useCallback((key, title, valueSelected) => {
        handleHistorical(key, title, valueSelected);

        handleWizardFlowProductsReset(key, valueSelected?.type ?? valueSelected);

        handleResetWizardIfParamsChanged(valueSelected?.type ?? valueSelected, key, {
            [constants.PARAM_FAULT]: true,
            [constants.PARAM_TOTAL_ORDER_PAID]: true
        });

    }, [wizardIndex, steps.length, setFlow]);

    useEffect(() => {
        resetHistoricalWhenTypeChanged();
    }, [flow.conditions?.type]);

    useEffect(() => {
        handleFlowStepsConfig();
    }, [flow, solutions, historicalFunction, order]);

    const handleGetNewSolutions = useCallback(() => {
        if (isClientResponsible()) return;

        const { conditions } = flow || {};
        const {
            fault,
            total_order_payed,
            solution
        } = conditions || {};

        if (fault !== undefined && total_order_payed !== undefined && solution === undefined) {
            getSolutions(
                order.compra_id,
                constants.TYPE_INCOMPLETE,
                { fault: fault, total_order_payed: total_order_payed }
            );
        }
    }, [flow?.conditions]);

    useEffect(() => {
        handleGetNewSolutions();
    }, [flow?.conditions]);

    const resetHistoricalWhenTypeChanged = () => {
        if (flow.conditions?.type !== constants.TYPE_INCOMPLETE) {
            resetWizardParams();
        }
    };

    const isClientResponsible = () => {
        if (flow?.conditions?.fault === constants.CLIENT_FAULT) {
            setClientFault({
                visible: true,
                message: '⛔️ El cliente no tiene razón, no se puede continuar con el flujo'
            });
            resetWizardParams();
            return true;
        }
        return false;
    };

    const handleFlowStepsConfig = () => {

        const baseSteps = [
            <OrderReceipt order={order} handleHistorical={historicalFunction} />,
            <UserPaymentInfo handleHistorical={historicalFunction} order={order} />,
            <UserBlame handleHistorical={historicalFunction} />,
            <UserSolution handleHistorical={historicalFunction} />,
            <ProductListWithQty handleHistorical={historicalFunction} products={order.allProductByPrv} />
        ];

        const currentSolution = flow.conditions?.solution;
        const getSolutionObj = solutions.find(item => item.type === currentSolution);

        let finalStep;
        if (currentSolution) {
            if (shouldDoSolutionOrder(getSolutionObj)) {
                finalStep = (
                    <ScheduleOrderSolution
                        handleHistorical={historicalFunction}
                        order={order}
                    />
                );
            } else {
                finalStep = (
                    <RefundMethod
                        handleHistorical={historicalFunction}
                    />
                );
            }
        }

        setSteps([...baseSteps, finalStep, <WizardLastStep order={order} />]);
    };

    return <FlowBodyRender
        clientFault={clientFault}
        setClientFault={setClientFault} />
};

export default Incomplete;