import { useCallback } from 'react';

export const constants = {
    PETSHOP_LOGISTIC: "Petshop",
    COLLECT_LOGISTIC: "Colecta",
    ON_DEMAND_LOGISTIC: "On demand",
    BLAME_MIS_PICHOS: 1,
    BLAME_MP_DELIVERY_MOTIVE: process.env.NODE_ENV === 'production' ? 1 : 223,
    TYPE_INCOMPLETE: "incomplete",

    TYPE_CONFUSED: "confused",
    TYPE_BROKED: "broken",
    TYPE_EXPIRED: "expired",
    TYPE_NOT_DELIVERED: "not_delivered",

    CLIENT_FAULT: "client",
    PET_FAULT: "pet",
    PICHO_PESOS: 'credit',
    CREDIT_CARD: 'card',

    COMPLETE_ORDER_SOLUTION: "complete_order",
    CHANGE_ORDER_SOLUTION: "change_order",

    PARAM_TOTAL_ORDER_PAID: "total_order_payed",
    PARAM_FAULT: "fault",
    PARAM_USER_ACCEPTED_PRODUCTS: "user_accepted_products",
    PARAM_SOLUTION: "solution",
    PARAM_USER_ACCEPTS_REPROGRAMMING: "user_accepts_reprogramming",
    PARAM_PRODUCTS: "products",
    PARAM_DIFFERENT_PRODUCTS: "different_products",
    PARAM_SOLUTION_ORDER: "solution_order",
    PARAM_PAYMENT_TYPE: 'payment_type',
    PARAM_CONFUSED_PRODUCT_ACCEPTED: 'confused_product_accepted',
    PARAM_BROKED_PRODUCT_ACCEPTED: 'broked_product_accepted',
    PARAM_BEFORE_DELIVERY_TIME: 'before_delivery_time',
    PARAM_USER_ACCEPTED_CHANGE: 'user_accepted_change',
    PARAM_RECEIPT: 'receipt',
    CLIENT_IMAGE_PROOF: 'image_proof',

    PARTIAL_CARD_REFUND: 'partial_refund_with_card',
    PARTIAL_CREDIT_REFUND: 'partial_refund_with_credit',
    FULL_CARD_REFUND: 'full_refund_with_card',
    FULL_CREDIT_REFUND: 'full_refund_with_credit',
    ACCEPT_ISSUE_AND_REWARD_WITH_CREDIT: 'accept_issue_and_reward_with_credit',

    CANCEL_ORDER_SOLUTION: 'cancelOrder',
    SPLIT_ORDER_SOLUTION: 'splitOrder',
    EDIT_ORDER_SOLUTION: 'editOrder',
    USER_ACCEPTS_REPROGRAMMING_SOLUTION: 'changeOrder',
    USER_WANTS_TO_BE_CONTACTED_SOLUTION: 'contactSupportPopup',

    SPLIT_LEGACY_MODAL_KEY: 'splitCompra',
    REFUND_LEGACY_MODAL_KEY: 'reembolso',
    EDIT_ORDER_LEGACY_MODAL_KEY: 'editarCompra',
    REPROGRAMMING_ORDER_LEGACY_MODAL_KEY: 'reprogramarCompra',
    CS_CONTACT_LEGACY_MODAL_KEY: 'showToContactByCs',
};

const useWizardReset = (flow, setClientFault, resetWizardParams) => {
    /**
     * Verifica si un parámetro ha cambiado y reinicia el wizard si es necesario
     * @param  value - El nuevo valor
     * @param  key - La clave del parámetro en flow.conditions
     * @param  requiredConditions - Objeto con las condiciones necesarias para realizar la verificación
     * @param  resetMessage - Mensaje a mostrar si se reinicia el wizard
     */
    const handleResetWizardIfParamsChanged = useCallback((
        value,
        key,
        requiredConditions = {},
        resetMessage = '⛔️ Se han cambiado parámetros críticos y el flujo se reiniciará.'
    ) => {
        const { conditions } = flow || {};

        if (!conditions) return;

        // Verificar si todas las condiciones requeridas se cumplen
        const allConditionsMet = Object.entries(requiredConditions).every(
            ([condKey, required]) => {
                // Verificar si la propiedad existe en el objeto, independientemente de su valor
                const conditionExists = condKey in conditions;

                // Obtener el valor de la condición
                const conditionValue = conditions[condKey];

                // Si required es false, no importa si existe o no
                // Si required es true, debe existir la propiedad
                const result = required === false || (conditionExists && conditionValue !== undefined);


                return result;
            }
        );

        // Verificar condición adicional si está especificada
        const additionalConditionMet =
            flow.conditions?.solution !== undefined;

        if (allConditionsMet && additionalConditionMet) {
            const actualParam = flow.conditions[key];

            // Si se cambió el param original por otro distinto, se reinicia el wizard
            if (actualParam !== undefined && value !== undefined && actualParam !== value) {
                setClientFault({
                    visible: true,
                    message: resetMessage
                });
                resetWizardParams();
                return true; // Se hizo reset
            }
        }

        return false; // No se hizo reset
    }, [flow, setClientFault, resetWizardParams]);

    return handleResetWizardIfParamsChanged;
};

export const shouldDoSolutionOrder = (solution) => {
    const keys = Object.keys(solution.params);

    if (keys.includes('tim_id') || keys.includes('date')) {
        return true;
    }

    return false;
};

export default useWizardReset;