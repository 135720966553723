
import AuthService from './AuthService';
import ROUTES from './ApiRoutes';

const Auth = new AuthService();

const ApiCoreV2 = {
    // Endpoint con doble uso. Si no tiene params, devuelve todas las soluciones. Si tiene params, devuelve las soluciones específicas para ese problema
    getWizardConfig: function (orderID, params, type = null) { return Auth.get((ROUTES.core_v2.get_wizard_config + (type ? `?problem=${type}` : '')).replace('{id}', orderID), params) },

    resolveWizard: function (params) { return Auth.post(ROUTES.core_v2.resolve_wizard, params); },

    getWizardSummary: function (orderID, params) { return Auth.post((ROUTES.core_v2.summary).replace('{id}', orderID), params) },
};

export default ApiCoreV2;
