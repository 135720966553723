import { createContext, useEffect, useState } from "react";
import ApiCoreV2 from "../../utils/ApiConnection/OliverCore/ApiService";
import { constants } from "../Utils";

export const WizardContext = createContext();

export const WizardProvider = ({ children }) => {

    const CASH_PAYMENT = 'efectivo';

    // Wizard
    const [flow, setFlow] = useState({});
    const [wizard, setWizard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [option, setOption] = useState(null);
    const [solutions, setSolutions] = useState([]);
    const [resolvedWizard, setResolvedWizard] = useState(null);
    const [wizardIndex, setWizardIndex] = useState(0);
    const [historical, setHistorical] = useState([]);
    const [steps, setSteps] = useState([]);
    const [loadingSolutions, setLoadingSolutions] = useState(false);
    const [images, setImages] = useState([]);
    const [solutionError, setSolutionError] = useState({
        visible: false,
        message: ''
    });
    const [forcePaymentInfoValue, setForcePaymentValue] = useState(null);

    // Modals
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [modalWizardResolved, setModalWizardResolved] = useState(false);

    const getSolutionParams = () => {
        return wizard.find(w => w.type === option)?.solutions.find(s => s.type === flow.conditions?.solution)?.params;
    };

    const resolveWizard = async (order_id, getWizardSummary = null) => {
        const solParams = getSolutionParams();
        const wizardParamsCollected = { ...flow.conditions };
        const wizardSolutionParamKeys = Object.keys(solParams);

        let conditions = {};
        wizardSolutionParamKeys.forEach(key => {
            conditions[key] = wizardParamsCollected[key];
        });

        conditions.problem = option;
        conditions.solution = flow.conditions.solution;

        const params = {
            conditions: conditions,
            order_id: order_id,
            products: flow.products
        };

        setShowConfirmationModal(false);

        setLoading(true);

        // Summmary
        if (getWizardSummary) {
            const summaryMessages = await ApiCoreV2.getWizardSummary(order_id, params)
                .then(response => {
                    const messages = <ul>{response.data.summary.map((el, index) => <li key={index}>{el}</li>)}</ul>
                    return messages;
                })
                .catch(error => {
                    alert(error.message);
                })
                .finally(() => {
                    setLoading(false)
                });
            return summaryMessages;
        }
        // Wizard resolve
        else {
            ApiCoreV2.resolveWizard(params)
                .then((response) => {
                    setResolvedWizard(response.data);
                    setShowConfirmationModal(false)
                    setModalWizardResolved(true);
                })
                .catch(error => alert(error))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const getSolutions = (order_id, type = '', params = {}) => {
        setLoadingSolutions(true);
        ApiCoreV2.getWizardConfig(order_id, params, type)
            .then((response) => {
                const sols = response.data.solutions;

                // Si se envía un tipo y parámetros, se obtienen las soluciones específicas para ese problema
                if (type && params) {
                    setSolutions(sols);
                }
                else {
                    setWizard(sols);
                }

            })
            .catch((error) => {
                setSolutionError({
                    visible: true,
                    message: error ?? 'No existen soluciones disponibles.'
                });
                setSolutions([]);
            })
            .finally(() => {
                setLoading(false);
                setLoadingSolutions(false);
            });
    };

    const handleWizardControls = (key) => {
        if (key !== constants.CLIENT_IMAGE_PROOF && key !== constants.PARAM_PRODUCTS && key !== constants.PARAM_SOLUTION_ORDER && key !== constants.PARAM_DIFFERENT_PRODUCTS && key !== constants.PARAM_PAYMENT_TYPE) {
            if (wizardIndex < steps.length - 1) {
                setWizardIndex(wizardIndex + 1);
            }
        }
    };


    const handleHistorical = (key, title, valueSelected) => {

        setHistorical(prev => {
            const getHistoricalObj = prev.find(item => item.key === key);
            if (getHistoricalObj) {
                getHistoricalObj.value = (key === constants.PARAM_SOLUTION) ? valueSelected.title : valueSelected;
                return prev.slice(0, prev.findIndex(item => item.key === key) + 1);
            } else {
                return [...prev, { key: key, value: (key === constants.PARAM_SOLUTION) ? valueSelected.title : valueSelected, title: title }];
            }
        });

        handleWizardControls(key, title, valueSelected);
    }

    const resetWizardParams = () => {
        setFlow({});
        setHistorical([]);
        setImages([]);
        setWizardIndex(0);
        return;
    };

    const handleMotiveSelection = (value) => {
        setFlow({ type: value, condition: {} });
        setOption(value);
    };

    const resolveWizardConfirmation = () => {
        setShowConfirmationModal(true);
    }

    const handleWizardResetForMismatchSolutions = () => {
        setSolutionError({
            visible: false,
            message: 'Con estas opciones seleccionadas no existe una solución posible.'
        });
    };

    const handleUserPaymentInfoRendering = (order) => {
        if (order) {
            const { compra_medio_pago } = order;
            setForcePaymentValue(compra_medio_pago === CASH_PAYMENT ? false : true);
            return;
        }

        setForcePaymentValue(null);
    };

    return (
        <WizardContext.Provider value={{
            //Modals
            showConfirmationModal,
            setShowConfirmationModal,
            modalWizardResolved,
            setModalWizardResolved,

            // States
            flow,
            setFlow,
            wizard,
            setWizard,
            loading,
            setLoading,
            option,
            setOption,
            solutions,
            setSolutions,
            resolvedWizard,
            setResolvedWizard,
            wizardIndex,
            setWizardIndex,
            historical,
            setHistorical,
            steps,
            setSteps,
            images,
            setImages,
            solutionError,
            setSolutionError,

            // funciones
            resolveWizard,
            getSolutions,
            resetWizardParams,
            handleHistorical,
            handleMotiveSelection,
            resolveWizardConfirmation,
            handleWizardResetForMismatchSolutions,
            handleUserPaymentInfoRendering,
            forcePaymentInfoValue,
            setForcePaymentValue,
            loadingSolutions
        }}>
            {children}
        </WizardContext.Provider>
    )

}