import { Row, Select } from "antd";
import { useContext } from "react";
import { FaDotCircle } from "react-icons/fa";
import { constants } from "../Utils";
import { WizardContext } from "../Context/WizardProvider";

const UserSolution = ({ handleHistorical }) => {
    const {
        flow,
        setFlow,
        solutions,
        loadingSolutions
    } = useContext(WizardContext);

    const handleSelection = (selectedType) => {
        const selectedSolution = solutions.find(sol => sol.type === selectedType);

        const updatedFlow = { ...flow };
        updatedFlow.conditions.solution = selectedType;

        setFlow(updatedFlow);
        handleHistorical(constants.PARAM_SOLUTION, 'Solución: ', selectedSolution);
    }

    return (
        <div style={{ marginTop: 10 }}>
            <Row className="d-flex align-items-center pb-2" style={{ gap: 5 }}>
                <FaDotCircle color={"deepskyblue"} />
                <strong style={{ fontSize: 14 }}>Solución</strong>
            </Row>
            <Select
                style={{ width: '100%' }}
                placeholder="Posibles soluciones"
                onChange={handleSelection}
                loading={loadingSolutions}
                value={flow.conditions.solution}>
                {!loadingSolutions ? solutions.map((solution, index) => (
                    <Select.Option key={index} value={solution.type}>
                        {solution.title}
                    </Select.Option>
                ))
                    : <Select.Option key={1} value={0} disabled>
                        <span>Buscando soluciones. Por favor espere.</span>
                    </Select.Option>
                }
            </Select>
        </div>
    );
};

export default UserSolution;
